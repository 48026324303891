import styled from "styled-components";

import leftQuotes from "../../assets/icons/left-quotes.png";

export const Container = styled.section`
  padding: 12rem 0;

  .testimonials__container {
    width: 100%;
    max-width: 920px;

    margin: 0 auto;
  }

  .testimonials__item {
    padding: 15px 7rem;

    display: flex;
    align-items: center;
  }

  .testimonials__wrapper {
    width: 100%;
    max-width: 470px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5rem;
  }

  .testimonials__text {
    color: #000;
    font-size: 2.3rem;
    font-family: "Poppins", sans-serif;
    text-align: center;

    position: relative;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      width: 8rem;
      height: 6rem;
      background: url(${leftQuotes}) no-repeat center center / contain;
      opacity: 0.5;

      position: absolute;
      left: -60px;
      top: -15px;
      z-index: -1;
    }
  }

  .testimonials__user {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    ${props => props.theme.breakpoints.down("xs")} {
      flex-direction: column;
    }
  }

  .testimonials__user-image {
    width: 8.7rem;
    height: 8.7rem;
    object-fit: cover;
    border-radius: 100%;
  }

  .testimonials__user-name {
    color: #ad807d;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    font-size: 2.3rem;

    ${props => props.theme.breakpoints.down("xs")} {
      text-align: center;
    }
  }
`;
