import styled from "styled-components";

import contentBg from "../../assets/images/winemakers/content-bg.png";
import slideBg from "../../assets/images/winemakers/slide-bg.jpg";

export const Container = styled.section`
  width: 100%;
  padding: 8rem 0;

  display: flex;

  ${props => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
  }

  .slider-section__content-wrapper {
    background: url(${contentBg}) no-repeat top center;
    padding: 3rem;
    padding-right: 9rem;

    height: auto;
    width: 100%;
    max-width: 480px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: unset;
      align-items: center;
      text-align: center;
      padding-right: 3rem;
    }
  }

  .slider-section__title {
    color: #262626;
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 3rem;
  }

  .slider-section__text {
    color: #707070;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }

  .slide {
    width: 100%;
    max-width: 400px;
    min-height: 500px;
  }

  .slide__image {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

  .slide__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    overflow: hidden;

    &:hover {
      .slide__content {
        bottom: 0;
        opacity: 1;
      }
    }
  }

  .slide__content {
    position: absolute;
    left: 0;
    bottom: -100%;

    width: 100%;
    background: url(${slideBg}) no-repeat top left / cover;
    padding: 2rem;

    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    opacity: 0;
    transition: bottom 0.3s, opacity 0.5s;

    ${props => props.theme.breakpoints.down("md")} {
      bottom: 0;
      opacity: 1;
    }
  }

  .slide__title {
    color: #fff;
    font-size: 2.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .slide__button {
    color: #fff !important;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    border: 1px solid #fff;
    border-radius: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 160px;
    padding: 1.3rem;

    transition: transform 0.3s;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .slider-section__link-wrapper {
    display: flex;
    justify-content: flex-end;

    padding-right: 10vw;
  }

  .slider-section__link {
    display: inline-block;
    color: #707070;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-top: 1rem;
  }
`;
