import iconSignUp from "../../assets/icons/newsletter-icon-signup.png";
import { Container } from "./styles";

interface NewsletterProps {
  purple?: boolean;
}

export function Newsletter({ purple = false }: NewsletterProps) {
  return (
    <Container purple={purple}>
      <div className="newsletter__container">
        <h1 className="newsletter__title">
          <img
            className="newsletter__title-img"
            src={iconSignUp}
            alt="Sign up to Newsletter"
          />
          <span>Sign up to NewsLetter</span>
        </h1>

        <span className="newsletter__text">
          ...and receive <strong>$20 coupon for first shopping.</strong>
        </span>

        <form className="newsletter-form">
          <input
            className="newsletter-form__input"
            type="text"
            placeholder="Enter your email address"
          />
          <button className="newsletter-form__submit" type="submit">
            Sign Up
          </button>
        </form>
      </div>
    </Container>
  );
}
