import { createContext, ReactNode, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { destroyCookie, setCookie } from 'nookies';

import { api } from "../services/api";

interface User {
  name: string;
  email: string;
  avatar: string;
};

interface SignInCredentials {
  login: string;
  password: string;
}

interface AuthContextData {
  signIn(credentials: SignInCredentials): Promise<void>;
  user: User | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthCountext = createContext({} as AuthContextData);

export function signOut() {
  destroyCookie(undefined, 'laquincave.token');
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = !!user;

  useEffect(() => {
    async function getUser() {
      try {
        const response = await api.get("/sessions/me.php");
        const { name, email, avatar } = response.data;

        setUser({ name, email, avatar });
      } catch (error) {
        signOut();
      }
    }

    getUser();
  }, []);

  async function signIn({ login, password }: SignInCredentials) {
    try {
      setIsLoading(true);

      const response = await api.post("/sessions/create.php", {
        login,
        password,
      });

      const { token, user } = response.data;

      setCookie(undefined, 'laquincave.token', token, {
        maxAge: 60 * 60 * 24 * 30, // 30 days
        path: "/"
      });

      setUser({
        name: user.name,
        email: user.email,
        avatar: user.avatar
      });

      api.defaults.headers['X-Authorization'] = `Bearer ${token}`;
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <AuthCountext.Provider value={{ signIn, user, isAuthenticated, isLoading }}>
      {children}
    </AuthCountext.Provider>
  );
}
