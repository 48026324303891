import { RefObject, useContext } from "react";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";

import { Box } from "@material-ui/core";

import { CartContext } from "../../contexts/CartContext";
import { envPrefix } from "../../utils/envPrefix";
import { priceFormat } from "../../utils/priceFormat";
import { ProductDivider } from "../ProductDivider";
import { Container } from "./styles";

interface CartDropdownProps {
  buttonRef: RefObject<HTMLButtonElement>;
}

export function CartDropdown({ buttonRef }: CartDropdownProps) {
  const {
    cart,
    cartTotal,
    removeProduct,
    increaseProductQuantity,
    decreaseProductQuantity,
  } = useContext(CartContext);

  function handleClose() {
    buttonRef.current?.click();
  }

  function handleRemoveClick(productId: string) {
    removeProduct(productId);
  }

  return (
    <Container data-uk-dropdown="mode: click; pos: bottom-right">
      {cart.map(product => (
        <div className="cart-dropdown__item-container" key={product.id}>
          <div className="cart-dropdown__product-wrapper">
            <img
              className="cart-dropdown__product-image"
              src={`${envPrefix}galeria/${product.img}`}
              alt={product.title}
            />
            <div className="cart-dropdown__product-title-wrapper">
              <ProductDivider />
              <span className="cart-dropdown__product-title">
                {product.title}
              </span>
            </div>
            <div>
              <span className="cart-dropdown__product-price">
                {priceFormat({ price: Number(product.price) })}
              </span>
              <div className="cart-dropdown__product-quantity">
                <button
                  onClick={() => decreaseProductQuantity(product.id)}
                  className="cart-dropdown__product-quantity-button"
                  type="button"
                >
                  <FaMinus />
                </button>
                <span className="cart-dropdown__product-quantity-number">
                  {product.quantity}
                </span>
                <button
                  onClick={() => increaseProductQuantity(product.id)}
                  className="cart-dropdown__product-quantity-button"
                  type="button"
                >
                  <FaPlus />
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="cart-dropdown__button-remove"
            onClick={() => handleRemoveClick(product.id)}
          >
            <FaTrash />
          </button>
        </div>
      ))}

      {cart.length ? (
        <>
          <div className="cart-dropdown__total-container">
            <span className="cart-dropdown__total-text">Total</span>
            <span className="cart-dropdown__product-price">
              {priceFormat({ price: cartTotal })}
            </span>
          </div>
          <Link
            to="/cart"
            className="cart-dropdown__checkout-button"
            onClick={handleClose}
          >
            Check out
          </Link>
        </>
      ) : (
        <Box textAlign="center" paddingRight="3rem">
          <span className="cart-dropdown__total-text">Your cart is empty.</span>
        </Box>
      )}
    </Container>
  );
}
