import { useState, useContext, FormEvent } from "react";
import { FaEye, FaEyeSlash, FaFacebookF, FaGoogle } from "react-icons/fa";
import { Link } from "react-router-dom";

import { AuthCountext } from "../../contexts/AuthCountext";
import { Loading } from "../Loading";

import { StyledLoginForm } from "./styles";

export function LoginForm() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { signIn, isLoading } = useContext(AuthCountext);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    await signIn({
      login,
      password,
    });
  }

  return (
    <StyledLoginForm onSubmit={handleSubmit}>
      <h1 className="login-form__title">Log on to</h1>
      <input
        className="login-form__input"
        type="text"
        placeholder="Username or email"
        value={login}
        onChange={e => setLogin(e.target.value)}
        required
        readOnly={isLoading}
      />
      <label className="login-form__password-wrapper">
        <input
          className="login-form__input"
          type={isPasswordVisible ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
          readOnly={isLoading}
        />
        <div onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
          {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
        </div>
      </label>
      <label className="login-form__check-wrapper">
        <input type="checkbox" name="remember-me" />
        <span>Remember me</span>
      </label>
      <div className="login-form__button-container">
        <Link to="/" className="login-form__create-account-button">
          Create an account
        </Link>
        <button className="login-form__login-button" type="submit">
          {isLoading && <Loading color="#fff" size="2rem" />}
          {!isLoading && 'Login'}
        </button>
      </div>
      <Link to="/" className="login-form__forgot-link">
        Forgot password?
      </Link>

      <div className="login-form__divider-wrapper">
        <span>or</span>
        <hr className="uk-divider-icon" />
      </div>

      <div className="login-form__media-button-container">
        <button
          type="button"
          className="login-form__media-button login-form__media-button--facebook"
        >
          <div className="login-form__media-icon-wrapper login-form__media-icon-wrapper--facebook">
            <FaFacebookF />
          </div>
          <span className="login-form__media-button-text login__media-button-text">
            Login with Facebook
          </span>
        </button>

        <button
          type="button"
          className="login-form__media-button login-form__media-button--google"
        >
          <div className="login-form__media-icon-wrapper login-form__media-icon-wrapper--google">
            <FaGoogle />
          </div>
          <span className="login-form__media-button-text login__media-button-text">
            Login with Google
          </span>
        </button>
      </div>
    </StyledLoginForm>
  );
}
