import { createGlobalStyle } from "styled-components";

import "typeface-poppins";
import "typeface-libre-baskerville";

export const GlobalStyle = createGlobalStyle`
  :root {
    --app-black: #212121;
    --app-yellow: #f9d889;
    --app-purple: #550057;
    --app-red: #b93e41;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    font-size: 1.6rem;
    font-family: 'Poppins';
    background: #fcf6f6;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
  }

  a,
  a:focus,
  a:hover {
    text-decoration: none;
    color: inherit;
  }
`;
