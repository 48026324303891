import { useCallback, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

import { Container as MuiContainer } from "@material-ui/core";

import { CollectionSection } from "../../components/CollectionSection";
import { NaturalWineSection } from "../../components/NaturalWineSection";
import { Newsletter } from "../../components/Newsletter";

import { api } from "../../services/api";
import { envPrefix } from "../../utils/envPrefix";

import { Header, FilterSection } from "./styles";

import illustrationWoman from "../../assets/images/wineries/illustration-woman.png";
import { Skeleton } from "@material-ui/lab";

interface HeaderContent {
  title: string;
  text: string;
}

interface Winery {
  id: string;
  title: string;
  slug: string;
  img: string;
}

export function Wineries() {
  const filterContainerRef = useRef(null);
  const [filter, setFilter] = useState("A");

  const { data: headerContent, isLoading: isLoadingHeaderContent } = useQuery("wineries_page_header_content", async () => {
    const response = await api.get<HeaderContent>("/wineries/page.php");
    return response.data;
  });

  const { data: wineries, isLoading: isLoadingWineries } = useQuery(["wineries_filter", filter], async () => {
    const response = await api.get<Winery[]>("/wineries/filter.php", {
      params: { filter }
    });

    return response.data;
  });

  const handleFilterButtonClick = useCallback((event) => {
    if (event.target.nodeName !== "BUTTON") {
      return;
    }

    const clickedButton: HTMLButtonElement = event.target;
    const activeButton: HTMLButtonElement = filterContainerRef.current.querySelector("button.active");

    activeButton.classList.remove("active");
    clickedButton.classList.add("active");

    setFilter(clickedButton.textContent);
  }, []);

  return (
    <main>
      <Helmet>
        <title>Laquincave | Wineries</title>
      </Helmet>

      {isLoadingHeaderContent && (
        <Skeleton variant="rect" height="500px" width="100%" />
      )}

      {!isLoadingHeaderContent && (
        <Header>
          <MuiContainer maxWidth="xl">
            <div className="wineries-header__container">
              <div className="wineries-header__content-wrapper">
                <h1 className="wineries-header__title">
                  {headerContent.title}
                </h1>
                <div className="wineries-header__text"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(headerContent.text) }}
                />
              </div>
              <img src={illustrationWoman} alt="Woman with basket of grapes" />
            </div>
          </MuiContainer>
        </Header>
      )}

      <FilterSection>
        <MuiContainer maxWidth="xl">
          <div className="wineries-filter-section__filter">
            <div
              ref={filterContainerRef}
              className="wineries-filter-section__filter-container"
              onClick={handleFilterButtonClick}
            >
              <button className="active">A</button>
              <button>B</button>
              <button>C</button>
              <button>D</button>
              <button>E</button>
              <button>F</button>
              <button>G</button>
              <button>H</button>
              <button>I</button>
              <button>J</button>
              <button>K</button>
              <button>L</button>
              <button>M</button>
              <button>N</button>
              <button>O</button>
              <button>P</button>
              <button>Q</button>
              <button>R</button>
              <button>S</button>
              <button>T</button>
              <button>U</button>
              <button>V</button>
              <button>W</button>
              <button>X</button>
              <button>Y</button>
              <button>Z</button>
            </div>
          </div>
          <div className="wineries-filter-section__result-container">
            {isLoadingWineries && (
              Array(3).fill(null).map((_item, index) => (
                <Skeleton key={index} variant="rect" width="100%" height="500px" />
              ))
            )}

            {!isLoadingWineries && (
              wineries.map(winery => (
                <Link to={`winery/${winery.slug}`} className="wineries-filter-result" key={winery.id}>
                  <img
                    className="wineries-filter-result__image"
                    src={`${envPrefix}/galeria/${winery.img}`}
                    alt={winery.title}
                  />
                  <div className="wineries-filter-result__footer">
                    <strong className="wineries-filter-result__title">
                      {winery.title}
                    </strong>
                    <span className="wineries-filter-result__button">
                      Know more
                    </span>
                  </div>
                </Link>
              ))
            )}
          </div>
        </MuiContainer>
      </FilterSection>

      <Newsletter purple />
      <CollectionSection />
      <NaturalWineSection />
    </main>
  );
}
