import axios from "axios";
import { parseCookies } from 'nookies';

import { envPrefix } from "../utils/envPrefix";

const URL = `${envPrefix}qube-manager/api/routes`;

const cookies = parseCookies();

export const api = axios.create({
  baseURL: URL,
  headers: {
    "X-Authorization": `Bearer ${cookies['laquincave.token']}`,
  },
});
