import styled from "styled-components";

import wineSectionTitleBg from "../../assets/images/winery/wine-section-title-bg.png";
import wineSectionTitleLeaves from "../../assets/images/winery/wine-section-title-leaves.png";
import winegrowerBgRight from "../../assets/images/winery/winegrower-bg-right.png";
import winegrowerLeaves from "../../assets/images/winery/winegrower-leaves.png";

export const WinegrowerSection = styled.section`
  padding: 12rem 0 8rem;
  background: url(${winegrowerBgRight}) no-repeat calc(1920px - 400px) top;

  .winegrower__image-row {
    width: 100%;
    max-width: 1270px;
    margin: 0 auto;

    display: flex;
    gap: 6rem;

    ${props => props.theme.breakpoints.down("md")} {
      flex-direction: column;
      align-items: center;
    }
  }

  .winegrower__image-wrapper {
    width: 100%;
    max-width: 620px;
    height: 850px;

    box-shadow: 10px 10px 1px var(--app-red);

    position: relative;

    &::before {
      content: url(${winegrowerLeaves});
      position: absolute;
      z-index: -1;
      left: -290px;
      bottom: 0;
    }
  }

  .winegrower__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .winegrower__image-row-content {
    width: 100%;
    max-width: 600px;
    padding-top: 4rem;
  }

  .winegrower__logo {
    margin-bottom: 7rem;
  }

  .winegrower__title {
    color: var(--app-red);
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    line-height: 3.5rem;
    margin: 0;
  }

  .winegrower__text * {
    display: block;
    color: #707070;
    font-size: 1.4rem;
    font-family: "Poppins" sans-serif;
    line-height: 2.7rem;
    margin: 0;
    margin-bottom: 4rem;
  }

  .winegrower__subtitle {
    color: #707070;
    font-size: 1.8rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    line-height: 2.7rem;
    margin: 0;
    margin-bottom: 2rem;
  }

  .winegrower__content-container {
    width: 100%;
    max-width: 835px;
    margin: 0 auto;

    margin-top: 12rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 6rem;

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: 600px;
      grid-template-columns: 1fr;
      margin-top: 4rem;
    }
  }

  .winegrower__content-title {
    color: var(--app-red);
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2.7rem;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .winegrower__content-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;

    * {
      display: block;
      color: #707070;
      font-size: 1.5rem;
      font-family: "Poppins" sans-serif;
      line-height: 3rem;
      margin: 0;
    }
  }
`;

export const WineSection = styled.section`
  .wine-section__title-container {
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
    margin-bottom: 8rem;

    position: relative;

    &::before {
      content: url(${wineSectionTitleLeaves});
      position: absolute;
      top: -50px;
      right: -50px;
    }
  }

  .wine-section__title-wrapper {
    position: relative;
    padding: 3rem;
    border-radius: 1rem;
    background: url(${wineSectionTitleBg}) no-repeat center center / cover;
  }

  .wine-section__title {
    color: var(--app-yellow);
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin: 0;
    text-align: center;

    span {
      color: #fff;
      font-size: 1.8rem;
      font-family: "Poppins", sans-serif;
      font-weight: normal;
      display: block;
      margin-top: 1rem;
    }
  }

  .wine-section__products-container {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
  }

  .wine-section__products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    grid-template-rows: auto;
    column-gap: 1.5rem;
    align-items: stretch;

    margin: 0 auto;

    ${props => props.theme.breakpoints.down("md")} {
      max-width: 960px;
      row-gap: 3rem;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: 440px;
    }

    ${props => props.theme.breakpoints.down("xs")} {
      grid-template-columns: 1fr;
    }
  }
`;
