import { Fragment } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { Skeleton } from "@material-ui/lab";
import { Container as MuiContainer } from "@material-ui/core";
import { Facebook, Instagram, YouTube, Twitter } from "@material-ui/icons";

import { api } from "../../services/api";

import {
  StyledFooter,
  Container,
  TopContainer,
  Address,
  Logo,
  LinkContainer,
  LinksTitle,
  FooterLink,
  LinkGrid,
  BottomContainer,
  Copyright,
  SocialMediaContainer,
  SocialMediaLink,
} from "./styles";

import logoImg from "../../assets/images/footer/footer-logo.png";

interface SettingsData {
  title: string;
  facebook: string;
  instagram: string;
  youtube: string;
  twitter: string;
  address: string;
  email: string;
  phone: string;
}

export function Footer() {
  const { data: settings, isLoading } = useQuery("settings", async () => {
    const response = await api.get<SettingsData>("/settings/all.php");
    return response.data;
  });

  return (
    <StyledFooter>
      <MuiContainer maxWidth="xl">
        <Container>
          <TopContainer>
            <Address>
              <Link to="/">
                <Logo src={logoImg} alt="La Quincave" />
              </Link>

              {isLoading && (
                <Fragment>
                  <Skeleton variant="text" style={{ backgroundColor: "#929292" }} />
                  <Skeleton variant="text" style={{ backgroundColor: "#929292" }} />
                  <Skeleton variant="text" style={{ backgroundColor: "#929292" }} />
                </Fragment>
              )}

              {!isLoading && (
                <Fragment>
                  <Link to={{ pathname: `https://www.google.com/maps/search/?api=1&query=${settings.address}` }} target="_blank">
                    17 rue Bréa Paris, France - 75006
                  </Link>

                  <a href={`mailto:${settings.email}`}>{settings.email}</a>

                  <span>{settings.phone}</span>
                </Fragment>
              )}
            </Address>

            <LinkContainer>
              <LinksTitle>Who we are</LinksTitle>
              <FooterLink to="/about">About</FooterLink>
              <FooterLink to="/">Team</FooterLink>
              <FooterLink to="/">Wineries</FooterLink>
              <FooterLink to="/">Videos</FooterLink>
              <FooterLink to="/">Blog</FooterLink>
              <FooterLink to="/">Contact</FooterLink>
              <FooterLink to="/">F.A.Q</FooterLink>
            </LinkContainer>

            <LinkContainer>
              <LinksTitle>Wine Catalog</LinksTitle>
              <FooterLink to="/">White wines</FooterLink>
              <FooterLink to="/">Red wines</FooterLink>
              <FooterLink to="/">Rosé Wines</FooterLink>
              <FooterLink to="/">All The Champagnes</FooterLink>
              <FooterLink to="/">Wines without added sulfites</FooterLink>
              <FooterLink to="/">Magnums</FooterLink>
            </LinkContainer>

            <div>
              <LinksTitle>Wine Regions</LinksTitle>
              <LinkGrid>
                <LinkContainer>
                  <FooterLink to="/">Alsace</FooterLink>
                  <FooterLink to="/">Auvergne</FooterLink>
                  <FooterLink to="/">Beaujolais</FooterLink>
                  <FooterLink to="/">Bordeaux</FooterLink>
                  <FooterLink to="/">Bugey</FooterLink>
                  <FooterLink to="/">Burgundy</FooterLink>
                  <FooterLink to="/">Champagne</FooterLink>
                  <FooterLink to="/">Corsica</FooterLink>
                  <FooterLink to="/">Jura</FooterLink>
                </LinkContainer>

                <LinkContainer>
                  <FooterLink to="/">Languedoc</FooterLink>
                  <FooterLink to="/">Loire valley</FooterLink>
                  <FooterLink to="/">Provence</FooterLink>
                  <FooterLink to="/">Rhône Valley</FooterLink>
                  <FooterLink to="/">Roussillon</FooterLink>
                  <FooterLink to="/">Savoy</FooterLink>
                  <FooterLink to="/">South-West of France</FooterLink>
                  <FooterLink to="/">Italy</FooterLink>
                </LinkContainer>
              </LinkGrid>
            </div>
          </TopContainer>

          <BottomContainer>
            <Copyright>
              © 2020 Lorem ipsum dolor sit amet consectetur adiscipli admet
            </Copyright>

            <SocialMediaContainer>
              {isLoading && (
                <Fragment>
                  <Skeleton variant="rect" width="22px" height="22px" style={{ backgroundColor: "#929292" }} />
                  <Skeleton variant="rect" width="22px" height="22px" style={{ backgroundColor: "#929292" }} />
                  <Skeleton variant="rect" width="22px" height="22px" style={{ backgroundColor: "#929292" }} />
                  <Skeleton variant="rect" width="22px" height="22px" style={{ backgroundColor: "#929292" }} />
                </Fragment>
              )}

              {!isLoading && (
                <Fragment>
                  {settings.facebook && (
                    <SocialMediaLink to={{ pathname: settings.facebook }} target="_blank">
                      <Facebook />
                    </SocialMediaLink>
                  )}

                  {settings.instagram && (
                    <SocialMediaLink to={{ pathname: settings.instagram }} target="_blank">
                      <Instagram />
                    </SocialMediaLink>
                  )}

                  {settings.youtube && (
                    <SocialMediaLink to={{ pathname: settings.youtube }} target="_blank">
                      <YouTube />
                    </SocialMediaLink>
                  )}

                  {settings.twitter && (
                    <SocialMediaLink to={{ pathname: settings.twitter }} target="_blank">
                      <Twitter />
                    </SocialMediaLink>
                  )}
                </Fragment>
              )}
            </SocialMediaContainer>
          </BottomContainer>
        </Container>
      </MuiContainer>
    </StyledFooter>
  );
}
