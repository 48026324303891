import { Slider as MuiRangeSlider } from "@material-ui/core";
import styled from "styled-components";

import accordionChevronDown from "../../assets/images/wine-list-sidebar-accordion-chevron-down.png";

export const Sidebar = styled.div`
  background: #fff;
  padding: 8rem 1.5rem;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.18);

  ${props => props.theme.breakpoints.down("md")} {
    padding: 0;
    box-shadow: none;
  }

  .wine-list-sidebar__container {
    width: 100%;
    max-width: 270px;
    margin: 0 auto;
  }

  .wine-list-sidebar__title {
    color: #333e48;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.8rem;
    border-bottom: 1px solid #dddddd;
    display: block;
    padding-bottom: 1rem;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      max-width: 70px;
      height: 2px;
      background: var(--app-red);
      position: absolute;
      bottom: -1px;
    }
  }

  .wine-list-sidebar__filter-group {
    border-bottom: 1px solid #ddd;
    padding-bottom: 2rem;
    margin-top: 2rem;

    .show-more-button {
      font-family: "Poppins";
      font-weight: 500;
      color: #575560;
      font-size: 1.3rem;
      border: none;
      background: none;
      margin-top: 2rem;
      cursor: pointer;
    }
  }

  .wine-list-sidebar__filter-group-title {
    color: #575560;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }

  .wine-list-sidebar__checkbox-container {
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.5rem;

    label {
      color: #333e48;
      font-size: 1.4rem;
      font-family: "Poppins";
      font-weight: 500;

      .uk-checkbox {
        margin-right: 0.7rem;
        border-radius: 3px;
        border-color: #dfdfdf !important;

        &:checked {
          background: var(--app-red) !important;
          border-color: var(--app-red) !important;
        }
      }

      .amount {
        color: #acacac;
      }
    }
  }

  .wine-list-sidebar__apply-button {
    color: #fff;
    font-size: 1.4rem;
    text-transform: unset;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    background: var(--app-red);
    border-radius: 1rem;
  }

  .wine-list-sidebar__reset-button {
    color: #333e48;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    background: none;
    text-transform: unset;
    padding: unset;
    line-height: unset;
    margin-left: 1.5rem;
  }

  /*****************************************************************************
  * Wine List Sidebar Accordion
  *****************************************************************************/

  .wine-list-sidebar-accordion {
    border: 2px solid #ececec;
    border-radius: 0.8rem;
    margin: 0;
    margin-bottom: 3rem;

    .amount {
      color: #acacac;
      font-size: 1.3rem;
    }

    .uk-accordion {
      margin: 0;
    }

    .uk-accordion-content {
      margin: 0;
    }

    .wine-list-sidebar-accordion__container {
      padding: 2rem 1.5rem;
    }

    .wine-list-sidebar-accordion__group + .wine-list-sidebar-accordion__group {
      margin-top: 3.5rem;
    }

    .wine-list-sidebar-accordion__categories-title {
      color: #333e48;
      font-size: 1.5rem;
      font-family: "Poppins", sans-serif;
      font-weight: 500;

      display: block;
      border-bottom: 1px solid #ececec;
      padding-bottom: 1rem;
      margin-bottom: 2rem;
    }

    .uk-accordion-title {
      color: #333e48;
      font-size: 1.5rem;
      font-family: "Poppins", sans-serif;
      font-weight: 500;

      width: 100%;
      text-align: start;
      background: none;
      border: none;
      cursor: pointer;

      &::before {
        content: "";
        background-image: url(${accordionChevronDown});
      }
    }

    .uk-accordion-title--first {
      padding: 2rem 1.5rem;
      border-bottom: 1px solid #ececec;
    }

    .wine-list-sidebar-accordion__category-item {
      margin: 0;

      & + .wine-list-sidebar-accordion__category-item {
        border-top: 1px solid #ececec;
        margin-top: 1.5rem;
        padding-top: 1.5rem;
      }

      .uk-accordion-title {
        color: #333e48;
        font-family: "Poppins", sans-serif;
        font-weight: 600;

        .amount {
          font-family: "Poppins", sans-serif;
          font-weight: 500;
        }

        svg {
          color: #b2b2b2;
          font-size: 2rem;
          margin-right: 0.3rem;
        }

        &::before {
          background-image: none;
        }
      }
    }

    .wine-list-sidebar-accordion__category-container {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;

      padding-top: 1rem;
      padding-left: 3.5rem;

      button {
        color: #333e48 !important;
        text-align: start;
        background: none;
        border: none;
        font-size: 1.5rem;
        font-family: "poppins", sans-serif;

        .amount {
          color: #b2b2b2;
          font-size: 1.3rem;
        }
      }
    }
  }

  /*******************************************************************************
  * Wine List Sidebar Price Slider
  *******************************************************************************/

  .wine-list-sidebar__price-slider-text {
    color: #686868;
    font-size: 1.4rem;
    display: block;
  }

  /*****************************************************************************
  * Latest Product Header
  *****************************************************************************/

  .latest-product-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .latest-product-header__icon-container {
    display: flex;
    font-size: 1.5rem;
    column-gap: 1rem;

    svg {
      cursor: pointer;
    }
  }

  /*****************************************************************************
  * Latest Product Slider
  *****************************************************************************/

  .latest-product-slider {
    margin-top: 2.5rem;
  }

  .latest-product-slider__item {
    overflow: hidden;
  }

  .latest-product-slider__product-wrapper {
    display: flex;
    gap: 1.5rem;

    & + .latest-product-slider__product-wrapper {
      margin-top: 3rem;
    }

    position: relative;

    img {
      min-width: 100px;
      height: 93px;
      max-width: unset;
      object-fit: cover;
    }

    &::after {
      display: block;
      content: "";
      height: 1px;
      width: 80%;
      background: #eaeaea;
      position: absolute;
      bottom: -1.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .latest-product-slider__product-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
  }

  .latest-product-slider__text {
    color: #707070;
    font-size: 1.5rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    line-height: 2rem;
  }

  .latest-product-slider__price {
    color: #333e48;
    font-size: 1.5rem;
    font-family: "Poppins";
    font-weight: bold;
  }
`;

export const StyledRangeSlider = styled(MuiRangeSlider)`
  .MuiSlider-rail {
    background-color: #cacaca;
  }

  .MuiSlider-track {
    height: 4px;
    background-color: #dcdcdc;
    top: 50%;
    transform: translateY(-50%);
  }

  .MuiSlider-thumb {
    width: 16px;
    height: 16px;
    margin-top: -7px;
    background-color: var(--app-red);

    &:hover {
      box-shadow: 0px 0px 0px 8px rgba(185, 62, 65, 0.16);
    }

    &[data-index="1"] {
      background-color: #fff;
      border: 2px solid var(--app-red);
    }
  }
`;

export const SidebarAd = styled.div`
  width: 100%;
  max-width: 270px;
  margin: 4rem auto 2rem;

  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;
