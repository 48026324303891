import { Helmet } from "react-helmet-async";
import { MdShoppingCart } from "react-icons/md";

import { Container as MuiContainer } from "@material-ui/core";

import headerIllustration from "../../assets/images/cart/cart-header-illustration.svg";
import { ProductCard } from "../../components/ProductCard";
import { ProductsTable } from "../../components/ProductsTable";
import { Header, ProductSection, MoreProducts } from "./styles";

export function Cart() {
  return (
    <main>
      <Helmet>
        <title>La Quincave | My Cart</title>
      </Helmet>

      <Header>
        <MuiContainer maxWidth="xl">
          <div className="cart-header__container">
            <h1 className="cart-header__title">Your Products in the Cart</h1>
            <img src={headerIllustration} alt="" />
            <span className="cart-header__text">
              Lorem ipsum dolor sit amet, consectetur
            </span>
          </div>
        </MuiContainer>
      </Header>

      <ProductSection>
        <MuiContainer maxWidth="xl">
          <div className="cart-product-section__container">
            <div className="cart-product-section__title-wrapper">
              <MdShoppingCart />
              <h1 className="cart-product-section__title">Chosen by you</h1>
            </div>
            <div className="cart-product__table-wrapper">
              <ProductsTable />
            </div>
          </div>
        </MuiContainer>
      </ProductSection>

      <MoreProducts>
        <MuiContainer maxWidth="xl">
          <div className="cart-more-products__container">
            <h1 className="cart-more-products__title">See Too</h1>
            <div className="cart-more-products__grid">
              {/* {Array(3).fill(null).map((_item, index) => (
                <ProductCard id={index} />
              ))} */}
            </div>
          </div>
        </MuiContainer>
      </MoreProducts>
    </main>
  );
}
