import styled from "styled-components";

export const Container = styled.div`
  width: 465px;
  padding: 3rem;
  padding-right: 0;
  overflow: hidden;
  border-radius: 1rem;

  .cart-dropdown__item-container {
    position: relative;
    background: var(--app-red);
    width: calc(100% + 15px);

    &:hover {
      .cart-dropdown__product-wrapper {
        transform: translateX(-60px);
      }
    }
  }

  .cart-dropdown__product-wrapper {
    position: relative;
    background: #fff;
    padding: 2rem 0;
    padding-right: calc(15px + 3rem);
    border-radius: 0 1.5rem 1.5rem 0;

    transition: transform 0.2s;
    z-index: 1;

    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
  }

  .cart-dropdown__product-image {
    width: 80px;
    height: 90px;
    object-fit: scale-down;
  }

  .cart-dropdown__product-title-wrapper {
    width: 100%;
    max-width: 160px;

    display: flex;
    flex-direction: column;
  }

  .cart-dropdown__product-title {
    color: #707070;
    font-size: 1.8rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .cart-dropdown__product-price {
    color: #3eb969;
    font-size: 1.8rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    display: block;
    margin-bottom: 1.5rem;
  }

  .cart-dropdown__product-quantity {
    border: 1px solid #262626;
    border-radius: 4px;
    min-width: 100px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cart-dropdown__product-quantity-button {
    cursor: pointer;

    height: 35px;
    padding-right: 12px;
    padding-left: 12px;

    border: none;
    background: none;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cart-dropdown__product-quantity-number {
    color: #707070;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
  }

  .cart-dropdown__button-remove {
    position: absolute;
    left: calc(100% - (15px + 3rem + 2rem));
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;

    height: 100%;
    padding: 2rem;
    background: none;
    border: none;

    color: #fff;
    font-size: 1.8rem;
    text-align: right;

    cursor: pointer;
  }

  .cart-dropdown__total-container {
    display: flex;
    justify-content: space-between;
    padding-right: 3rem;
    margin-top: 1.5rem;
  }

  .cart-dropdown__total-text {
    color: #707070;
    font-size: 1.8rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
  }

  .cart-dropdown__checkout-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 250px;
    height: 40px;
    margin: 0 auto;
    margin-top: 1.5rem;

    color: #fff !important;
    background: #3eb969;
    font-family: "Poppins", sans-serif;

    border: none;
    border-radius: 1rem;

    cursor: pointer;

    transition: transform 0.2s;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;
