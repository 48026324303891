import { Container as MuiContainer } from "@material-ui/core";

import userImg from "../../assets/temp/testimonials-user.jpg";
import { Container } from "./styles";

export function Testimonials() {
  return (
    <Container>
      <MuiContainer maxWidth="xl">
        <div className="testimonials__container">
          <div
            className="uk-position-relative"
            data-tabindex="-1"
            data-uk-slider="autoplay: true; autoplay-interval: 6000"
          >
            <ul className="uk-slider-items uk-child-width-1-1">
              <li className="testimonials__item">
                <div className="testimonials__wrapper">
                  <span className="testimonials__text">
                    Lorem ipsum dolor sit amet, consectetur, sed do eiusmod
                    tempor incididunt.
                  </span>
                  <div className="testimonials__user">
                    <img
                      className="testimonials__user-image"
                      src={userImg}
                      alt="[NAME]"
                    />
                    <span className="testimonials__user-name">
                      Fanny Carsin
                    </span>
                  </div>
                </div>
              </li>
              <li className="testimonials__item">
                <div className="testimonials__wrapper">
                  <span className="testimonials__text">
                    Lorem ipsum dolor sit amet, consectetur, sed do eiusmod
                    tempor incididunt.
                  </span>
                  <div className="testimonials__user">
                    <img
                      className="testimonials__user-image"
                      src={userImg}
                      alt="[NAME]"
                    />
                    <span className="testimonials__user-name">
                      Fanny Carsin
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <div
              style={{ cursor: "pointer" }}
              className="uk-position-center-left uk-position-small uk-hidden-hover"
              data-uk-slidenav-previous
              data-uk-slider-item="previous"
            />
            <div
              style={{ cursor: "pointer" }}
              className="uk-position-center-right uk-position-small uk-hidden-hover"
              data-uk-slidenav-next
              data-uk-slider-item="next"
            />
          </div>
        </div>
      </MuiContainer>
    </Container>
  );
}
