import styled from "styled-components";

import headerBg from "../../assets/images/past-events/header-bg.png";
import sectionBg from "../../assets/images/past-events/section-bg.png";

export const Header = styled.header`
  background: url(${headerBg}) no-repeat center center / cover;
  padding: 8rem 0;

  .past-events-header__container {
    width: 100%;
    max-width: 1270px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;

    ${props => props.theme.breakpoints.down("md")} {
      flex-direction: column-reverse;
    }
  }

  .past-events-header__content-wrapper {
    width: 100%;
    max-width: 620px;
  }

  .past-events-header__title {
    color: #f9d889;
    font-size: 2.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    line-height: 3.5rem;
    margin-bottom: 2rem;

    ${props => props.theme.breakpoints.down("md")} {
      margin-top: 4rem;
    }
  }

  .past-events-header__text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    line-height: 2.7rem;
    margin: 0;
  }

  .past-events-header__image-wrapper {
    width: 100%;
    max-width: 560px;
  }

  .past-events-header__image {
    height: 100%;
    width: 100%;
    border: 5px solid #fff;
    box-shadow: 10px 10px 1px #ee4e4f;
    transform: rotate(-3deg);
  }
`;

export const PastEvenstSection = styled.section`
  background: url(${sectionBg}) no-repeat right top;
  padding-top: 8rem;

  ${props => props.theme.breakpoints.down(1921)} {
    background-position-x: calc(1920px - 470px);
  }

  .custom-slider-wrapper {
    margin: 15rem 0 5rem;

    ${props => props.theme.breakpoints.down("md")} {
      margin: 6rem 0 0;
    }
  }

  .past-events-section__title {
    color: var(--app-red);
    font-size: 2.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    line-height: 3.5rem;
    margin-bottom: 3rem;
  }

  .past-events-section__text {
    color: #707070;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    line-height: 2.7rem;
    margin: 0;
  }

  .past-events-section__video-column-wrapper {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
  }

  .past-events-section__video-column {
    width: 100%;
    max-width: 1580px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 3rem;

    padding-right: 1.5rem;

    ${props => props.theme.breakpoints.down("md")} {
      padding-left: 1.5rem;
      flex-direction: column;
    }
  }
  .past-events-section__video-column-content {
    width: 100%;
    max-width: 620px;
  }

  .past-events-section__content-grid {
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    margin-top: 12rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
    row-gap: 4rem;

    ${props => props.theme.breakpoints.down("md")} {
      margin-top: 3.5rem;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: 620px;
      grid-template-columns: 1fr;
    }
  }
`;

export const PlayerWrapper = styled.div`
  width: 100%;
  max-width: 860px !important;
  height: 630px;
  border-radius: 0.5rem;
  box-shadow: -2rem 2rem 1px var(--app-red);

  ${props => props.theme.breakpoints.down("md")} {
    height: 500px;
    margin-bottom: 8rem;
  }
`;
