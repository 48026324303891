import { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import { About } from "../pages/About";
import { Cart } from "../pages/Cart";
import { Contact } from "../pages/Contact";
import { Events } from "../pages/Events";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { PastEvents } from "../pages/PastEvents";
import { WineBox } from "../pages/WineBox";
import { WineBoxInner } from "../pages/WineBoxInner";
import { WineList } from "../pages/WineList";
import { Wineries } from "../pages/Wineries";
import { Winery } from "../pages/Winery";

export function Routes() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" component={About} />
      <Route path="/cart" component={Cart} />
      <Route path="/contact" component={Contact} />
      <Route path="/events" component={Events} />
      <Route path="/login" component={Login} />
      <Route path="/past-events" component={PastEvents} />
      <Route path="/wineries" component={Wineries} />
      <Route path="/winery/:slug" component={Winery} />
      <Route path="/wine-box" exact component={WineBox} />
      <Route path="/wine-box/:slug" component={WineBoxInner} />
      <Route path="/wine-list" component={WineList} />
    </Switch>
  );
}
