import styled from "styled-components";

import headerBg from "../../assets/images/cart/cart-header-bg.png";

export const Header = styled.header`
  padding: 12rem 0 8rem 0;
  background: url(${headerBg}) no-repeat center bottom / cover;

  .cart-header__container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;

    ${props => props.theme.breakpoints.down("md")} {
      text-align: center;
      flex-direction: column;
      row-gap: 3rem;
    }
  }

  .cart-header__title {
    color: #faad99;
    font-size: 6.4rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    margin: 0;
  }

  .cart-header__text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.8rem;
    max-width: 200px;
  }
`;

export const ProductSection = styled.section`
  padding: 8rem 0 3rem 0;

  .cart-product-section__container {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
  }

  .cart-product-section__title-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;

    svg {
      color: #d63c3f;
      font-size: 5rem;
    }

    ${props => props.theme.breakpoints.down("md")} {
      flex-direction: column;
      text-align: center;
    }
  }

  .cart-product-section__title {
    margin: 0;
    color: #707070;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-size: 3.6rem;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .cart-product__table-wrapper {
    margin-top: 6rem;
  }
`;

export const MoreProducts = styled.section`
  padding: 3rem 0 15rem 0;

  .cart-more-products__container {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
  }

  .cart-more-products__title {
    color: #707070;
    font-size: 3.6rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5rem;

    ${props => props.theme.breakpoints.down("md")} {
      text-align: center;
    }
  }

  .cart-more-products__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    grid-template-rows: auto;
    column-gap: 1.5rem;
    align-items: stretch;

    margin: 0 auto;

    ${props => props.theme.breakpoints.down("md")} {
      max-width: 960px;
      row-gap: 3rem;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: 440px;
    }

    ${props => props.theme.breakpoints.down("xs")} {
      grid-template-columns: 1fr;
    }
  }
`;
