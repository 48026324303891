import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  margin-top: 8rem;

  ${props => props.theme.breakpoints.down("sm")} {
    margin-top: 4rem;
  }

  .custom-slider {
    align-items: flex-end;
  }

  .custom-slider__slide-wrapper {
    width: 850px;
    padding: 0 4rem;
  }

  .custom-slider__image {
    width: 100%;
    height: 500px;
    object-fit: cover;

    ${props => props.theme.breakpoints.down("xs")} {
      height: unset;
    }
  }

  .custom-slider__subtitle {
    display: block;
    margin: 0 auto;
    padding: 3rem 6rem 0;

    color: #000;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    font-size: 2.3rem;
    text-align: center;

    background: #fcf6f6;

    ${props => props.theme.breakpoints.down("xs")} {
      font-size: 1.8rem;
      padding: 3rem 3rem 0;
    }
  }

  .custom-slider__navigation-wrapper {
    width: 100%;
    max-width: 870px;

    display: flex;
    justify-content: space-between;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    pointer-events: none;

    div {
      pointer-events: all;
    }
  }
`;
