import styled from "styled-components";

import attractionBg from "../../assets/images/events/attraction-bg.png";
import bgDots from "../../assets/images/events/bg-dots.png";
import countdownBg from "../../assets/images/events/slider-countdown-container-bg.png";
import sliderSectionBg from "../../assets/images/events/slider-section-bg.png";

export const Header = styled.header`
  position: relative;

  .events-header-slider__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    ${props => props.theme.breakpoints.down("md")} {
      grid-template-columns: unset;
    }
  }

  .events-header-slider__image {
    width: 100%;
    min-height: 680px;
    object-fit: cover;
  }

  .events-header-slider__countdown-container {
    background: url(${countdownBg}) no-repeat;
    background-size: cover;
    padding: 3rem 6rem 3rem 9.5rem;

    ${props => props.theme.breakpoints.down("md")} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 500px;
      padding: 6rem 1.5rem;
    }
  }

  .events-header-slider__wrapper {
    width: 100%;
    max-width: 650px;
    margin-left: auto;

    ${props => props.theme.breakpoints.down("md")} {
      margin-left: unset;
    }
  }

  .events-header-slider__title {
    color: #fff;
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 9rem;
    margin-top: 13rem;
    text-align: center;

    ${props => props.theme.breakpoints.down("md")} {
      margin-top: 3rem;
      margin-bottom: 6rem;
    }
  }

  .events-header-countdown-container {
    display: flex;
    justify-content: space-between;
    column-gap: 1.5rem;

    ${props => props.theme.breakpoints.down("md")} {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 3rem;
    }
  }

  .events-header-countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    user-select: none;
  }

  .events-header-countdown__number {
    background: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 3.8rem;
    color: var(--app-red);
    height: 8.5rem;
    min-width: 8.5rem;
    border-radius: 50%;
    padding: 0 0.5rem;
    border: 2px solid #faad99;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .events-header-countdown__text {
    color: #f9d889;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }

  .events-header-slider__image-wrapper {
    ${props => props.theme.breakpoints.down("md")} {
      display: none;
    }
  }

  .events-header-slider-navigation {
    cursor: pointer;
    color: #fff !important;

    height: 7rem;
    min-width: 7rem;
    border: 2px solid #fff;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 46%;
    left: 1rem;

    svg {
      font-size: 3rem;
    }

    & + .events-header-slider-navigation {
      left: unset;
      right: 1rem;
    }

    ${props => props.theme.breakpoints.down("md")} {
      display: none;
    }
  }

  .events-header-slider-dotnav {
    display: none;

    li {
      a {
        background: rgba(255, 255, 255, 0.5);
        color: #fff;
      }

      &.uk-active a {
        background: #fff;
      }
    }

    ${props => props.theme.breakpoints.down("md")} {
      display: flex;
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-45%);
    }
  }
`;

export const EventsSliderSection = styled.section`
  padding: 0;
  margin-top: -9rem;
  position: relative;

  ${props => props.theme.breakpoints.down("md")} {
    margin-top: 6rem;
  }

  .next-events-section__slider-container {
    position: relative;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    .uk-grid-small {
      margin-left: -15px;
    }

    ${props => props.theme.breakpoints.down("md")} {
      max-width: 480px;
    }

    ${props => props.theme.breakpoints.down("xs")} {
      max-width: 230px;
    }
  }

  .next-events-section__slider-side-title {
    color: #fff;
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    display: inline-block;
    align-self: flex-end;
    margin-bottom: 3rem;
  }

  .events-slider-section__container {
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;

    min-height: 350px;

    background: #550057 url(${sliderSectionBg}) no-repeat bottom left;
    padding: 3rem 1.5rem;
  }
`;

export const EventsVideoSection = styled.section`
  padding: 8rem 0;
  min-height: 820px;
  background: url(${bgDots}) no-repeat center bottom;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props => props.theme.breakpoints.down("md")} {
    min-height: unset;
  }

  .events-video-section__container {
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 8rem;

    ${props => props.theme.breakpoints.down("md")} {
      grid-template-columns: 1fr 1fr;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: 640px;
      grid-template-columns: 1fr;
    }
  }

  .events-video-section__content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .events-video-section__subtitle {
    color: var(--app-red);
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
  }

  .events-video-section__title {
    color: var(--app-purple);
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    margin: 0;
    margin-bottom: 6rem;
  }

  .events-video-section__text {
    color: #5e5d6c;
    font-size: 1.8rem;
    font-family: "Poppins", sans-serif;
    margin: 0;
  }

  .player-wrapper {
    position: relative;
    width: 100%;
    height: 570px;

    ${props => props.theme.breakpoints.down("md")} {
      height: 100%;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      height: 400px;
    }
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const EventsAttraction = styled.section`
  padding: 6rem 0;
  background: url(${attractionBg}) no-repeat center center / cover;
  min-height: 650px;

  ${props => props.theme.breakpoints.down("md")} {
    background: #d63c3f;
    min-height: unset;
  }

  .events-attraction__container {
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
  }

  .events-attraction__grid {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 3rem;

    ${props => props.theme.breakpoints.down("md")} {
      flex-direction: column;
      align-items: center;
    }
  }

  .events-attraction__content {
    width: 100%;
    max-width: 450px;

    ${props => props.theme.breakpoints.down("md")} {
      margin-top: 6rem;
    }
  }

  .events-attraction__title {
    color: #fff;
    font-size: 4.2rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    margin-bottom: 4rem;
  }

  .events-attraction-article {
    & + .events-attraction-article {
      margin-top: 5rem;
    }
  }

  .events-attraction-article__title {
    color: #f0d888;
    font-size: 2.2rem;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1.5rem;
  }

  .events-attraction-article__text {
    color: #fff;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
    margin: 0;
  }
`;

export const EventsPromoBanner = styled.section`
  margin-bottom: 10rem;

  .events-promo-banner__container {
    width: 100%;
    max-width: 1460px;
    margin: 0 auto;
  }
`;
