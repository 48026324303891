import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { Box, createTheme, StylesProvider } from "@material-ui/core";
import { ThemeProvider } from "styled-components";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { AuthProvider } from "./contexts/AuthCountext";
import { CartProvider } from "./contexts/CartContext";
import { Routes } from "./routes";

import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.min.js";
import "uikit/dist/js/uikit-icons.min.js";

import "react-toastify/dist/ReactToastify.css";

import { GlobalStyle } from "./styles/global";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
});

const queryClient = new QueryClient();

export default function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <CartProvider>
            <StylesProvider injectFirst>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <BrowserRouter>
                  <Header />
                  <Box display="flex" flexDirection="column" flex="1 0 auto">
                    <Routes />
                  </Box>
                  <Footer />
                  <ToastContainer theme="colored" />
                </BrowserRouter>
              </ThemeProvider>
            </StylesProvider>
          </CartProvider>
        </HelmetProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </AuthProvider>
  );
}
