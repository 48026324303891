import { Fragment, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { AuthCountext } from '../../../contexts/AuthCountext';

interface AuthenticatedUserRedirectProps {
  to?: string;
}

export function AuthenticatedUserRedirect({ to="/" }: AuthenticatedUserRedirectProps) {
  const { isAuthenticated } = useContext(AuthCountext);

  return (
    <Fragment>
      {isAuthenticated && <Redirect to={{ pathname: to }} />}
    </Fragment>
  )
}
