import styled, { css } from "styled-components";

interface ProductDividerProps {
  wineDrop?: string;
}

export const Container = styled.div`
  padding: 5rem 1.8rem 4rem 1.8rem;

  display: flex;
  flex-direction: column;

  transition: background 0.2s, box-shadow 0.2s;

  &:hover {
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.18);
  }

  ${props => props.theme.breakpoints.down("md")} {
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.18);
  }

  /*****************************************************************************
  * Product Card header
  *****************************************************************************/

  .product-card__header {
    position: relative;
    margin-bottom: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-card__header-button-container {
    display: flex;
    justify-content: space-between;

    position: absolute;
    top: 0;
    width: 100%;
  }

  .product-card__header-button {
    color: #707070;
    font-size: 1.8rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;

    background: none;
    border: none;

    cursor: pointer;

    display: flex;
    align-items: center;
    column-gap: 7px;
  }

  .product-card__image {
    width: 100%;
    height: 240px;
    object-fit: contain;
  }

  /*****************************************************************************
  * Product Card Footer
  *****************************************************************************/

  .product-card__footer {
    flex: 1;

    margin-top: 2.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-card__title {
    color: #707070;
    font-size: 1.8rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 2.5rem;
    text-align: center;

    width: 100%;
    max-width: 300px;
  }

  .product-card__text {
    color: #707070;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: 2.5rem;
  }

  .product-card__price {
    color: #3eb969;
    font-size: 2.3rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: auto;
  }

  .product-card__footer-button-container {
    width: 100%;
    max-width: 300px;

    display: flex;
    justify-content: space-between;
    column-gap: 10px;
  }

  .product-card__quantity {
    flex: 1;

    border: 1px solid #262626;
    border-radius: 1rem;

    display: flex;
    justify-content: space-between;
  }

  .product-card__quantity-button {
    font-size: 1.5rem;

    padding: 5px 10px;
    cursor: pointer;

    border: none;
    background: none;
  }

  .product-card__quantity-number {
    align-self: center;
    font-size: 2rem;
  }

  .product-card__add-button {
    flex: 1;

    color: #fff;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;

    background: #3eb969;
    padding: 5px;
    border: none;
    border-radius: 1rem;

    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    transition: transform 0.2s;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;

export const ProductDivider = styled.hr<ProductDividerProps>`
  margin-bottom: 1rem;
  margin: 0;
  background-size: contain;

  ${props =>
    props.wineDrop &&
    css`
      background-image: url(${props.wineDrop});
    `}

  &::before, &::after {
    border-bottom: 2px solid #e7d9dd;
  }
`;
