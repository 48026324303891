import { CircularProgress } from "@material-ui/core";

import { Container } from "./styles";

interface LoadingProps {
  color?: string;
  size?: string;
}

export function Loading({ color = "#b93e41", size="10rem" }: LoadingProps) {
  return (
    <Container color={color}>
      <CircularProgress size={size} />
    </Container>
  );
}
