import { Container as MuiContainer } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { Container } from './styles';

import postImg from '../../assets/temp/blog-section-post.png';
import arrowRight from '../../assets/icons/icon-arrow-right-red.png';

export function BlogSection() {
  return (
    <Container>
      <MuiContainer maxWidth="xl">
        <div className="blog-section__container">
          <div className="blog-section__slider-wrapper" data-tabindex="-1" data-uk-slider="autoplay: true; autoplay-interval: 6000">

            <div className="uk-slider-container uk-padding-small">
              <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@l uk-flex-middle" data-uk-grid>
                {Array(4).fill(null).map((_, index) => (
                  <li key={index}>
                    <div className="blog-section-post">
                      <Link to="/" className="blog-section-post__image-wrapper">
                        <div className="blog-section-post__date-wrapper">
                          <span className="blog-section-post__day">23</span>
                          <span className="blog-section-post__month">april</span>
                        </div>
                        <img className="blog-section-post__image" src={postImg} alt="[POST TITLE]" />
                      </Link>
                      <div className="blog-section-post__content-container">
                        <div className="blog-section-post__content-wrapper">
                          <h1 className="blog-section-post__category">Trends, wines, decoration</h1>
                          <h2 className="blog-section-post__title">Lorem ipsum dolor sit amet aint</h2>
                          <p className="blog-section-post__text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Quis ipsum suspendisse...
                          </p>

                          <Link to="/" className="blog-section-post__link">
                            Continue Reading
                            <img src={arrowRight} alt="Continue Reading" className="uk-margin-small-left" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <ul className="blog-section__dotnav uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

            <div
              style={{cursor: 'pointer'}}
              className="blog-section__slidenav uk-position-center-left-out"
              data-uk-slidenav-previous
              data-uk-slider-item="previous"
            />
            <div
              style={{cursor: 'pointer'}}
              className="blog-section__slidenav uk-position-center-right-out"
              data-uk-slidenav-next
              data-uk-slider-item="next"
            />
          </div>
        </div>
      </MuiContainer>
    </Container>
  )
}
