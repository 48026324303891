import { Fancybox } from "../Fancybox";

import { envPrefix } from "../../utils/envPrefix";

import { Container } from "./styles";

import nextArrow from "../../assets/icons/custom-slider-next-arrow.png";
import previousArrow from "../../assets/icons/custom-slider-previous-arrow.png";

interface CustomSliderProps {
  hasSubtitles?: boolean;
  images: string[];
}

export function CustomSlider({ images, hasSubtitles = false }: CustomSliderProps) {
  return (
    <Container data-uk-slider="center: true; clsActivated: uk-transition-active">
      <ul className="custom-slider uk-slider-items">
        {images.map((image, index) => (
          <li className="custom-slider__slide-wrapper" key={index}>
            <Fancybox dataFancybox="gallery" dataSrc={`${envPrefix}/galeria/${image}`}>
              <img
                className="custom-slider__image"
                src={`${envPrefix}/galeria/${image}`}
                alt=""
              />
            </Fancybox>
            {hasSubtitles && (
              <div className="uk-position-bottom uk-hiden uk-visible@s">
                <strong className="custom-slider__subtitle uk-transition-slide-bottom">
                  At vero eos et accusamus et iusto odio dignissimos qui
                  blanditiis praesentium voluptatum.
                </strong>
              </div>
            )}
          </li>
        ))}
      </ul>

      <div className="custom-slider__navigation-wrapper">
        <div style={{ cursor: "pointer" }} data-uk-slider-item="previous">
          <img src={previousArrow} alt="Previous Arrow" />
        </div>
        <div style={{ cursor: "pointer" }} data-uk-slider-item="next">
          <img src={nextArrow} alt="Next Arrow" />
        </div>
      </div>
    </Container>
  );
}
