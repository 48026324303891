import { useState, useContext } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { IoStarSharp } from "react-icons/io5";

import { CartContext } from "../../contexts/CartContext";
import { Product } from "../../types/product";
import { envPrefix } from "../../utils/envPrefix";
import { priceFormat } from "../../utils/priceFormat";
import { Container, ProductDivider } from "./styles";

import winedropPurple from "../../assets/icons/winedrop-purple.png";
import winedropRed from "../../assets/icons/winedrop-red.png";
import winedropRose from "../../assets/icons/winedrop-rose.png";
import winedropYellow from "../../assets/icons/winedrop-yellow.png";
import productImg from "../../assets/temp/product-wine.png";

interface ProductCardProps {
  product: Product;
}

export function ProductCard({ product }: ProductCardProps) {
  const { addProduct } = useContext(CartContext);
  const [quantity, setQuantity] = useState(0);

  function handleDecreaseQuantityClick() {
    const newQuantity = quantity - 1;

    if (newQuantity >= 0) {
      setQuantity(newQuantity);
    }
  }

  function handleIncreaseQuantityClick() {
    const newQuantity = quantity + 1;

    if (newQuantity >= 0) {
      setQuantity(newQuantity);
    }
  }

  function handleAddToCartClick(product: Product) {
    const productToBeAdded = {
      ...product,
      quantity,
    };

    addProduct(productToBeAdded);
    setQuantity(0);
  }

  return (
    <Container>
      <div className="product-card__header">
        <div className="product-card__header-button-container">
          <button className="product-card__header-button">
            <IoStarSharp fontSize="2.3rem" color="#b93e41" />
            4,5
          </button>
          <button className="product-card__header-button">
            <AiOutlineHeart fontSize="2.6rem" />
          </button>
        </div>
        <img
          className="product-card__image"
          src={`${envPrefix}galeria/${product.img}`}
          alt="[TITLE]"
        />
      </div>

      <ProductDivider className="uk-divider-icon" wineDrop="" />

      <div className="product-card__footer">
        <strong className="product-card__title">{product.title}</strong>
        <span className="product-card__text">{product.country}</span>
        <span className="product-card__price">
          {priceFormat({ price: Number(product.price) })}
        </span>
        <div className="product-card__footer-button-container">
          <div className="product-card__quantity">
            <button
              className="product-card__quantity-button"
              type="button"
              onClick={handleDecreaseQuantityClick}
            >
              <FaMinus />
            </button>
            <span className="product-card__quantity-number">{quantity}</span>
            <button
              className="product-card__quantity-button"
              type="button"
              onClick={handleIncreaseQuantityClick}
            >
              <FaPlus />
            </button>
          </div>
          <button
            type="button"
            className="product-card__add-button"
            onClick={() => handleAddToCartClick(product)}
          >
            Add to cart
          </button>
        </div>
      </div>
    </Container>
  );
}
