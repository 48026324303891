import { useQuery } from 'react-query';
import { Helmet } from "react-helmet-async";
import { MdMenu } from "react-icons/md";
import { Link } from "react-router-dom";

import { Container as MuiContainer, Box } from "@material-ui/core";

import headerIllustration from "../../assets/images/wine-list/wine-list-header-illustration.png";
import { CollectionSection } from "../../components/CollectionSection";
import { Loading } from "../../components/Loading";
import { Newsletter } from "../../components/Newsletter";
import { ProductCard } from "../../components/ProductCard";
import { WineListSidebarContent } from "../../components/WineListSidebarContent";
import { api } from "../../services/api";
import { Product } from "../../types/product";
import {
  Header,
  WineListContent,
  StyledBreadcrumbs,
  StyledPagination,
} from "./styles";

export function WineList() {
  const { data: products, isLoading } = useQuery('products', async () => {
    const response = await api.get<Product[]>("products/all.php");
    return response.data;
  });

  return (
    <main>
      <Helmet>
        <title>La Quincave | Wine List</title>
      </Helmet>

      <Header>
        <MuiContainer maxWidth="xl">
          <div className="wine-list-header__container">
            <img
              className="wine-list-header__illustration"
              src={headerIllustration}
              alt="Promo Illustration"
            />
            <div className="wine-list-header__bonus">
              <span className="wine-list-header__bonus-number">15%</span>
              <span className="wine-list-header__bonus-text">bonus</span>
            </div>
            <div className="wine-list-header__text-container">
              <span className="wine-list-header__text">
                On your next purchase.
              </span>
              <span className="wine-list-header__text">
                Check out the promotion
              </span>
              <Link to="/wine-list" className="wine-list-header__button">
                check the promo
              </Link>
            </div>
          </div>
        </MuiContainer>
      </Header>

      <WineListContent>
        {/* Off-canvas Sidebar */}
        <div
          id="wine-list-sidebar"
          className="wine-list-sidebar-offcanvas"
          data-uk-offcanvas="overlay: true"
        >
          <div className="uk-offcanvas-bar">
            <button
              className="uk-offcanvas-close"
              type="button"
              data-uk-close
            />
            <WineListSidebarContent />
          </div>
        </div>

        {/* <!-- Sidebar --> */}
        <div className="uk-visible@l">
          <WineListSidebarContent />
        </div>

        {/* <!-- Products --> */}
        <div className="wine-list-products">
          <h1 className="wine-list-products__title">Our Wines</h1>
          <div className="wine-list-products__header">
            <StyledBreadcrumbs separator=">" aria-label="breadcrumb">
              <Link to="/">Home</Link>
              <Link to="/wine-list">Wine List</Link>
            </StyledBreadcrumbs>

            <button
              className="wine-list-sidebar-toggle"
              data-uk-toggle="target: #wine-list-sidebar"
              type="button"
            >
              <MdMenu />
            </button>

            <div className="wine-list-products__sort-container">
              <label className="wine-list-products__sort-label">Sort by</label>
              <select className="wine-list-products__sort-select">
                <option value="#">Lorem ipsum dolor</option>
                <option value="#">Lorem ipsum dolor</option>
                <option value="#">Lorem ipsum dolor</option>
              </select>
            </div>
          </div>

          {isLoading && <Loading />}

          {!isLoading && (
            <div className="wine-list-products__grid">
              {products.map(product => (
                <ProductCard product={product} key={product.id} />
              ))}
            </div>
          )}

          <div className="wine-list-products__footer">
            <div className="wine-list-products__pagination-info">
              <select className="wine-list-products__pagination-select">
                <option value="10">Show 10</option>
                <option value="20">Show 20</option>
              </select>
              <span className="wine-list-products__pagination-text">
                Showing 20 of 20 results
              </span>
            </div>

            <StyledPagination
              count={18}
              variant="outlined"
              hideNextButton
              hidePrevButton
            />
          </div>
        </div>
      </WineListContent>

      <Newsletter />

      <Box marginBottom="2rem">
        <CollectionSection />
      </Box>
    </main>
  );
}
