import styled from "styled-components";

import aboutBg from "../../assets/images/home/home-about-content-bg.png";
import homePromoBg from "../../assets/images/home/home-promo-bg.png";
import shopHoursBg from "../../assets/images/home/shop-hours-bg.png";
import shopHoursFigure1 from "../../assets/images/home/shop-hours-figure-1.png";
import shopHoursFigure2 from "../../assets/images/home/shop-hours-figure-2.png";

export const HomeAbout = styled.section`
  padding: 8rem 0;

  .home-about__cards-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
  }

  .home-about-content {
    margin-top: 10rem;
    background: url(${aboutBg}) no-repeat -200px center;

    ${props => props.theme.breakpoints.down("sm")} {
      background-position: center top;
    }
  }

  .home-about-content__container {
    max-width: 1230px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    column-gap: 3rem;
    row-gap: 6rem;

    ${props => props.theme.breakpoints.down("md")} {
      justify-content: space-around;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      flex-direction: column;
      align-items: center;
    }
  }

  .home-about-content__wrapper {
    width: 100%;
    max-width: 460px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .home-about-content__title {
    color: #262626;
    font-size: 4.3rem;
    text-transform: uppercase;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
  }

  .home-about-content__text {
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #707070;
    margin: 5rem 0;
  }

  .home-about-content__button {
    color: #fff !important;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    background: var(--app-red);
    border: none;
    border-radius: 1rem;
    cursor: pointer;

    width: 100%;
    height: 100%;
    max-height: 4.5rem;
    max-width: 14rem;
    padding: 1rem;

    transition: 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .home-about-content__image {
    ${props => props.theme.breakpoints.down("md")} {
      max-height: 500px;
    }
  }
`;

export const ShopHours = styled.section`
  overflow: hidden;

  padding: 0;
  background-color: var(--app-yellow);
  background-image: url(${shopHoursBg});
  background-position: center;

  .shop-hours__container {
    max-width: 1600px;
    margin: 0 auto;

    padding-top: 6rem;
    padding-bottom: 6rem;
    background: radial-gradient(
      circle,
      rgba(249, 216, 137, 1) 75%,
      rgba(249, 216, 137, 0.3) 100%
    );

    ${props => props.theme.breakpoints.down("md")} {
      max-width: 960px;
    }
  }

  .shop-hours__title {
    color: var(--app-red);
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 3.5rem;
  }

  .shop-hours__list-wrapper {
    display: flex;
    align-items: flex-end;

    ${props => props.theme.breakpoints.down("md")} {
      max-width: 640px;
      margin: 0 auto;

      flex-direction: column;
      align-items: center;
      row-gap: 4rem;
    }

    &::before {
      content: url(${shopHoursFigure1});

      ${props => props.theme.breakpoints.down("md")} {
        display: none;
      }
    }

    &::after {
      content: url(${shopHoursFigure2});
    }
  }

  .shop-hours-list {
    width: 100%;
    min-width: 500px;

    list-style: none;
    padding: 0;
    margin: 0;

    ${props => props.theme.breakpoints.down("xs")} {
      min-width: unset;
      text-align: center;
    }
  }

  .shop-hours-list__item {
    position: relative;
    margin-top: 2rem;

    display: flex;
    justify-content: space-between;

    &::before {
      content: "";
      position: absolute;
      bottom: 6px;
      width: 100%;
      height: 0;
      border-bottom: 2px dotted #262626;
    }

    ${props => props.theme.breakpoints.down("xs")} {
      display: flex;
      flex-direction: column;
    }
  }

  .shop-hours-list__day {
    color: #262626;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    background: var(--app-yellow);
    padding-right: 2px;
    z-index: 1;
  }

  .shop-hours-list__hours {
    color: #262626;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    background: var(--app-yellow);
    padding-left: 2px;

    white-space: nowrap;
    z-index: 1;

    ${props => props.theme.breakpoints.down("xs")} {
      white-space: normal;
    }
  }
`;

export const HomePromo = styled.section`
  padding-top: 2rem;

  .home-promo__container {
    width: 100%;
    max-width: 1460px;
    height: 300px;

    margin: 0 auto;
    padding: 3rem;

    background: #e9b33c url(${homePromoBg}) no-repeat center bottom / cover;
    border: 5px solid var(--app-purple);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6rem;

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: 640px;

      flex-direction: column;
      height: auto;
    }
  }

  .home-promo__content-wrapper {
    max-width: 510px;
  }

  .home-promo__title {
    color: var(--app-purple);
    font-size: 2.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
  }

  .home-promo__text {
    color: #262626;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin: 2.5rem 0;
  }

  .home-promo__button {
    color: #fff !important;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    background: var(--app-red);
    border-radius: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 180px;
    padding: 1.3rem;

    transition: transform 0.3s;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;
