import styled from "styled-components";

interface ContainerProps {
  color: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;

  circle {
    color: ${props => props.color};
  }
`;
