import styled from "styled-components";

export const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .login-form__title {
    color: #c1b2b7;
    font-size: 2.3rem;
    font-weight: bold;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  .login-form__input {
    color: #262626;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;

    width: 100%;
    height: 50px;
    padding: 1.5rem;
    border-radius: 1rem;
    border: 1px solid #a1a0a4;

    &::placeholder {
      color: #a0a0a0;
    }
  }

  .login-form__password-wrapper {
    display: flex;
    align-items: center;

    height: 50px;
    background: #fff;
    padding: 1.5rem;
    border-radius: 1rem;
    border: 1px solid #a1a0a4;

    input {
      flex: 1;
      padding: 0;
      border: none;
      border-radius: 0;
      height: calc(50px - 1.5rem);
    }

    svg {
      color: #262626;
      font-size: 1.8rem;

      margin-left: 1rem;

      cursor: pointer;
      opacity: 0.5;
    }
  }

  .login-form__check-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .login-form__button-container {
    display: flex;
    column-gap: 2rem;
    margin-top: 0.5rem;
  }

  .login-form__create-account-button {
    flex: 1;
    white-space: nowrap;

    color: #a0a0a0 !important;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    border-radius: 1rem;
    border: 1px solid #a1a0a4;
    padding: 1.4rem;

    transition: transform 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .login-form__login-button {
    flex: 1;

    color: #fff;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    background: #3eb969;
    border: none;
    border-radius: 1rem;
    padding: 1.4rem;

    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .login-form__forgot-link {
    color: var(--app-red) !important;
    align-self: center;
    margin-top: 1.5rem;

    transition: transform 0.2s;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .login-form__divider-wrapper {
    position: relative;

    span {
      color: #c1b2b7;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .uk-divider-icon {
      background-image: none;
      margin: 10px 0;
    }
  }

  /*****************************************************************************
  * Login Media Button
  *****************************************************************************/

  .login-form__media-button-container {
    button + button {
      margin-top: 1.5rem;
    }
  }

  .login-form__media-button {
    width: 100%;
    height: 4rem;
    border: none;
    border-radius: 1.5rem;

    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;

    cursor: pointer;

    display: flex;
    align-items: center;

    transition: transform 0.2s;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .login-form__media-button--facebook {
    background-color: #3b5999;
  }

  .login-form__media-button--google {
    background-color: #f44236;
  }

  .login-form__media-icon-wrapper {
    height: 100%;
    width: 5rem;
    border-radius: 1.5rem 0 0 1.5rem;
    font-size: 1.8rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-form__media-icon-wrapper--facebook {
    background: #253c68;
  }

  .login-form__media-icon-wrapper--google {
    background: #c53127;
  }

  .login-form__media-button-text {
    margin-left: 4.5rem;
  }
`;
