import styled from "styled-components";

export const Container = styled.section`
  padding-top: 8rem;
  padding-bottom: 4rem;

  .collection__container {
    width: 100%;
    max-width: 1460px;

    margin: 0 auto;

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: 640px;
    }
  }

  .collection__title {
    color: #c1b2b7;
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    text-transform: uppercase;

    margin-bottom: 4.5rem;

    ${props => props.theme.breakpoints.down("sm")} {
      text-align: center;
    }

    ${props => props.theme.breakpoints.down(390)} {
      font-size: 3rem;
    }
  }

  .collection__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
    row-gap: 3rem;

    ${props => props.theme.breakpoints.down("sm")} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .collection__grid-item {
    transition: all 0.3s;
    user-select: none;

    &:hover {
      filter: brightness(0.9);
      transform: translateY(-5px);
    }
  }

  .collection__more-wines-link {
    font-family: "Poppins", sans-serif;
    font-weight: 600;

    display: flex;
    align-items: center;
    gap: 1rem;

    max-width: max-content;
    margin-left: auto;
    margin-top: 3rem;

    transition: transform 0.3s;

    &:hover {
      transform: translateX(5px);
    }
  }
`;
