import styled from "styled-components";

import bgImg from "../../assets/images/video-section/bg.png";

export const StyledVideSection = styled.section`
  padding: 8rem 0;
  min-height: 820px;
  background: url(${bgImg}) no-repeat center center / cover;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props => props.theme.breakpoints.down("md")} {
    min-height: unset;
  }

  .video-section__container {
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 8rem;

    ${props => props.theme.breakpoints.down("md")} {
      grid-template-columns: 1fr 1fr;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: 640px;
      grid-template-columns: 1fr;
    }
  }

  .video-section__content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 6rem;
  }

  .video-section__title {
    color: #ffd058;
    font-size: 4.3rem;
    font-family: "Libre Baskerville", sans-serif;
    font-weight: bold;
    margin: 0;
  }

  .video-section__text {
    * {
      color: #fff;
      font-size: 1.4rem;
      font-family: "Poppins", sans-serif;
      line-height: 4rem;
      margin: 0;
    }
  }

  .video-section__button {
    color: #fff !important;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    border: 1px solid #fff;
    border-radius: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 180px;
    padding: 1.3rem;

    transition: transform 0.3s;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .video-section__iframe {
    width: 100%;
    height: 570px;

    ${props => props.theme.breakpoints.down("md")} {
      height: 100%;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      height: 400px;
    }
  }

  .player-wrapper {
    position: relative;

    width: 100%;
    height: 570px;

    ${props => props.theme.breakpoints.down("md")} {
      height: 100%;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      height: 400px;
    }
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
