import { Link } from "react-router-dom";

import { Container as MuiContainer } from "@material-ui/core";

import arrowRight from "../../assets/icons/icon-arrow-right-red.png";
import { NextEventsSectionCard } from "../NextEventsSectionCard";
import { Container } from "./styles";

interface NextEventsSectionProps {
  purple?: boolean;
}

export function NextEventsSection({ purple = false }: NextEventsSectionProps) {
  return (
    <Container purple={purple}>
      <MuiContainer maxWidth="xl">
        <div className="next-events-section__container">
          <div className="next-events-section__slider-side">
            <h1 className="next-events-section__slider-side-title">
              Next Events
            </h1>
            <div
              className="next-events-section__slider-container uk-light"
              data-uk-slider
            >
              <div className="uk-slider-container">
                <ul className="uk-slider-items uk-grid-small">
                  {Array(10)
                    .fill(null)
                    .map((_, index) => (
                      <li className="uk-width-1-2@s uk-width-1-3@l" key={index}>
                        <NextEventsSectionCard index={index} />
                      </li>
                    ))}
                </ul>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="uk-position-center-left-out"
                data-uk-slidenav-previous
                data-uk-slider-item="previous"
              />
              <div
                style={{ cursor: "pointer" }}
                className="uk-position-center-right-out"
                data-uk-slidenav-next
                data-uk-slider-item="next"
              />
            </div>
            <Link to="/events" className="next-events-section__schedule-button">
              View schedule
              <img src={arrowRight} alt="Arrow Right" />
            </Link>
          </div>

          <div className="next-events-section__event-side">
            <div className="next-events-section__event-side-content">
              <h1 className="next-events-section__event-side-title">
                Lorem Ipsum Dolor Sit amet consectetur
              </h1>
              <p className="next-events-section__event-side-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices...
              </p>
              <Link
                to="/events"
                className="next-events-section__event-side-button"
              >
                View Schedule
              </Link>
            </div>
          </div>
        </div>
      </MuiContainer>
    </Container>
  );
}
