import { useQuery } from 'react-query';
import { Link } from "react-router-dom";

import { Container as MuiContainer } from "@material-ui/core";

import arrowRightImg from "../../assets/icons/icon-arrow-right-red.png";
import { api } from "../../services/api";
import { envPrefix } from "../../utils/envPrefix";
import { Loading } from "../Loading";
import { Container } from "./styles";

interface Collection {
  id: string;
  titulo: string;
  img: string;
}

export function CollectionSection() {
  const { data: collections, isLoading } = useQuery('collections', async () => {
    const response = await api.get<Collection[]>("/collections/all.php");
    return response.data;
  });


  return (
    <Container>
      <MuiContainer maxWidth="xl">
        <div className="collection__container">
          <h1 className="collection__title">Our Collection</h1>

          {isLoading && <Loading />}

          {!isLoading && (
            <div className="collection__grid">
              {collections.map(collection => (
                collection.id !== "1000" && (
                  <div key={collection.id}>
                    <Link to="/" className="collection__grid-item">
                      <img
                        src={`${envPrefix}galeria/${collection.img}`}
                        alt={collection.titulo}
                      />
                    </Link>
                  </div>
                )
              ))}
            </div>
          )}

          <Link to="/" className="collection__more-wines-link">
            More wines
            <img src={arrowRightImg} alt="More Wines" />
          </Link>
        </div>
      </MuiContainer>
    </Container>
  );
}
