import { ReactNode, useEffect } from "react";

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

interface FancyboxProps {
  delegate?: string;
  options?: object;
  children: ReactNode;
  dataSrc?: string;
  dataFancybox?: string;
  dataCaption?: string;
}

export function Fancybox(props: FancyboxProps) {
  const delegate = props.delegate || "[data-fancybox]";

  useEffect(() => {
    const opts = props.options || {};

    NativeFancybox.bind(delegate, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, [delegate, props.options]);

  return (
    <div
      style={{ cursor: "pointer" }}
      data-fancybox={props.dataFancybox}
      data-src={props.dataSrc}
      data-caption={props.dataCaption}
    >
      {props.children}
    </div>
  );
}
