import styled from "styled-components";

import BgBottles from "../../assets/images/wineries/bg-bottles.png";
import resultFooterBg from "../../assets/images/wineries/filter-result-footer-bg.jpg";
import headerBg from "../../assets/images/wineries/wineries-header-bg.png";

export const Header = styled.header`
  padding: 8rem 0;
  background: url(${headerBg}) no-repeat center center / cover;

  .wineries-header__container {
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;

    display: flex;
    column-gap: 1.5rem;
    align-items: center;
    justify-content: space-between;

    ${props => props.theme.breakpoints.down("sm")} {
      flex-direction: column;
      row-gap: 6rem;
    }
  }

  .wineries-header__content-wrapper {
    width: 100%;
    max-width: 600px;
  }

  .wineries-header__title {
    color: var(--app-yellow);
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 4.5rem;

    ${props => props.theme.breakpoints.down("xs")} {
      font-size: 4rem;
    }
  }

  .wineries-header__text {
    * {
      color: #fff;
      font-size: 1.4rem;
      font-family: "Poppins", sans-serif;
      margin: 0;
    }
  }
`;

export const FilterSection = styled.section`
  padding: 8rem 0;
  background: linear-gradient(0deg, #fcf6f6 75%, rgba(252, 246, 246, 0.9) 100%),
    url(${BgBottles});

  .wineries-filter-section__filter {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;

    background: #434343;
    border-radius: 6px;
    padding: 3rem;
  }

  .wineries-filter-section__filter-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    display: flex;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;

    ${props => props.theme.breakpoints.down("sm")} {
      justify-content: center;
    }

    button {
      color: #a1a1af;
      font-family: "Poppins", sans-serif;
      font-size: 1.6rem;
      text-transform: uppercase;

      padding: 0.5rem;
      background: none;
      border: none;

      cursor: pointer;

      transition: color 0.2s;

      &.active {
        color: #fff;
        border: 2px solid var(--app-red);
        border-radius: 100%;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .wineries-filter-section__result-container {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;

    margin-top: 8rem;

    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(3, 1fr);

    ${props => props.theme.breakpoints.down("md")} {
      max-width: 960px;
      grid-template-columns: repeat(2, 1fr);
    }

    ${props => props.theme.breakpoints.down("xs")} {
      grid-template-columns: 1fr;
    }
  }

  .wineries-filter-result {
    display: flex;
    flex-direction: column;
  }

  .wineries-filter-result__image {
    width: 100%;
    height: 360px;
    object-fit: cover;
  }

  .wineries-filter-result__footer {
    flex: 1;

    padding: 2rem;
    background: url(${resultFooterBg}) no-repeat center center / cover;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
  }

  .wineries-filter-result__title {
    color: #fff;
    font-size: 2.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .wineries-filter-result__button {
    color: #fff !important;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    border: 1px solid #fff;
    border-radius: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 160px;
    padding: 1.3rem;

    transition: transform 0.3s;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;
