import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Box, Container as MuiContainer } from "@material-ui/core";
import DOMPurify from "dompurify";

import { CustomSlider } from "../../components/CustomSlider";
import { NaturalWineSection } from "../../components/NaturalWineSection";
import { Newsletter } from "../../components/Newsletter";
import { ProductCard } from "../../components/ProductCard";

import { api } from "../../services/api";
import { envPrefix } from "../../utils/envPrefix";
import { Product } from "../../types/product";

import { WinegrowerSection, WineSection } from "./styles";

import { Skeleton } from "@material-ui/lab";
import { Fragment } from "react";

interface WineryParams {
  slug: string;
}

interface WineryData {
  title: string;
  subtitle: string;
  description: string;
  certifications: string;
  grapesTheyBuy: string;
  grapesTheyFarm: string;
  technicalInformation: string;
  defaultImg: string;
  logo: string;
  gallery: string[];
  wines: Product[];
}

export function Winery() {
  const { slug } = useParams<WineryParams>();

  const { data: winery, isLoading } = useQuery(["Winery", slug], async () => {
    const response = await api.get<WineryData>("/wineries/winery.php", {
      params: { slug }
    });

    return response.data;
  });

  return (
    <main>
      <WinegrowerSection>
        <MuiContainer maxWidth="xl">
          <div className="winegrower__image-row">
            <div className="winegrower__image-wrapper">
              {isLoading ? (
                <Skeleton variant="rect" width="100%" height="100%" />
              ) : (
                <img
                  className="winegrower__image"
                  src={`${envPrefix}/galeria/${winery.defaultImg}`}
                  alt={winery.title}
                />
              )}
            </div>
            <div className="winegrower__image-row-content">
              {isLoading ? (
                <Fragment>
                  <Box marginBottom="7rem">
                    <Skeleton variant="rect" width="200px" height="200px" />
                  </Box>

                  <Box marginBottom="2rem">
                    <Skeleton variant="text" width="150px" />
                    <Skeleton variant="text" width="150px" />
                  </Box>

                  {Array(4).fill(null).map((_item, index) => (
                    <Box marginBottom="4rem" key={index}>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </Box>
                  ))}
                </Fragment>
              ) : (
                <Fragment>
                  {winery.logo && (
                    <img
                      className="winegrower__logo"
                      src={`${envPrefix}/galeria/${winery.logo}`}
                      alt={winery.title}
                    />
                  )}

                  <h1 className="winegrower__title">{winery.title}</h1>
                  <span className="uk-display-block uk-margin-bottom">
                    {winery.subtitle}
                  </span>

                  <div
                    className="winegrower__text"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(winery.description) }}
                  />

                  <h2 className="winegrower__subtitle">Certifications</h2>
                  <div
                    className="winegrower__text"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(winery.certifications) }}
                  />

                  <h3 className="winegrower__subtitle">Technical Information</h3>
                  <div
                    className="winegrower__text"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(winery.technicalInformation) }}
                  />
                </Fragment>
              )}
            </div>
          </div>
          <div className="winegrower__content-container">
            {isLoading ? (
              <Fragment>
                <Box marginBottom="6rem">
                  <Skeleton variant="text" style={{ marginBottom: "1.5rem" }} />
                  {Array(5).fill(null).map((_item, index) => (
                    <Skeleton variant="text" key={index} />
                  ))}
                </Box>

                <Box>
                  <Skeleton variant="text" style={{ marginBottom: "1.5rem" }} />
                  {Array(5).fill(null).map((_item, index) => (
                    <Skeleton variant="text" key={index} />
                  ))}
                </Box>
              </Fragment>
            ) : (
              <Fragment>
                <div>
                  <h3 className="winegrower__content-title">
                    Information on grapes they farm
                  </h3>
                  <div
                    className="winegrower__content-wrapper"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(winery.grapesTheyFarm) }}
                  />
                </div>
                <div>
                  <h3 className="winegrower__content-title">
                    Information on grapes they buy in
                  </h3>
                  <div
                    className="winegrower__content-wrapper"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(winery.grapesTheyBuy) }}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </MuiContainer>

        {isLoading ? (
          <Box marginTop="7rem">
            <Skeleton variant="rect" width="100%" height="500px" />
          </Box>
        ) : winery.gallery.length && (
          <CustomSlider images={winery.gallery} />
        )}
      </WinegrowerSection>

      <WineSection>
        <MuiContainer maxWidth="xl">
          <div className="wine-section__title-container">
            <div className="wine-section__title-wrapper">
              <h1 className="wine-section__title">
                Our Wines
                <span>of the winegrower</span>
              </h1>
            </div>
          </div>

          <div className="wine-section__products-container">
            <div className="wine-section__products-grid">
              {isLoading ? (
                Array(3).fill(null).map((_item, index) => (
                  <Skeleton variant="rect" width="100%" height="600px" key={index} />
                ))
              ) : winery.wines.length > 0 && (
                winery.wines.map((wine) => (
                  <ProductCard product={wine} key={wine.id}/>
                ))
              )}
            </div>
          </div>
        </MuiContainer>
      </WineSection>

      <Box zIndex="1" position="relative">
        <Newsletter purple />
      </Box>

      <Box marginTop="-3rem">
        <NaturalWineSection />
      </Box>
    </main>
  );
}
