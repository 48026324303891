import productImg from "../../assets/temp/cart-dropdown-product-red.png";
import { Container } from "./styles";

export function ProductsTable() {
  return (
    <Container>
      <table className="products-table uk-table">
        <thead className="products-table__thead">
          <tr>
            <th className="products-table__th">Product</th>
            <th className="products-table__th uk-text-center">Quantity</th>
            <th className="products-table__th">Total</th>
          </tr>
        </thead>
        <tbody className="products-table__tbody">
          <tr className="products-table__tbody-row">
            <td className="products-table__td">
              <div className="products-table__product-wrapper">
                <img
                  className="products-table__image"
                  src={productImg}
                  alt="[PRODUCT-TITLE]"
                />
                <span className="products-table__product-title">
                  Lorem ipsum dolor sit amet
                </span>
              </div>
            </td>
            <td className="products-table__td products-table__quantity">1</td>
            <td className="products-table__td products-table__price">
              $000,00
            </td>
          </tr>
          <tr className="products-table__tbody-row">
            <td className="products-table__td">
              <div className="products-table__product-wrapper">
                <img
                  className="products-table__image"
                  src={productImg}
                  alt="[PRODUCT-TITLE]"
                />
                <span className="products-table__product-title">
                  Lorem ipsum dolor sit amet
                </span>
              </div>
            </td>
            <td className="products-table__td products-table__quantity">1</td>
            <td className="products-table__td products-table__price">
              $000,00
            </td>
          </tr>
          <tr className="products-table__tbody-row">
            <td className="products-table__td">
              <div className="products-table__product-wrapper">
                <img
                  className="products-table__image"
                  src={productImg}
                  alt="[PRODUCT-TITLE]"
                />
                <span className="products-table__product-title">
                  Lorem ipsum dolor sit amet
                </span>
              </div>
            </td>
            <td className="products-table__td products-table__quantity">1</td>
            <td className="products-table__td products-table__price">
              $000,00
            </td>
          </tr>
        </tbody>
      </table>
      <div className="products-table-footer">
        <div className="products-table-footer__checkout-wrapper">
          <span>Coupon Code</span>
          <div className="products-table-footer__actions-container">
            <form className="products-table-footer__form">
              <input
                className="products-table-footer__input-coupon"
                type="text"
                placeholder="Enter Coupon Code Here.."
              />
              <button
                className="products-table-footer__apply-button"
                type="submit"
              >
                Apply
              </button>
            </form>
            <button className="products-table-footer__button-continue">
              Continue
            </button>
          </div>
        </div>
        <table className="purchase-summary-table uk-table">
          <tbody>
            <tr>
              <td className="purchase-summary-table__title">Cart Subtotal</td>
              <td className="purchase-summary-table__text">$0000,00</td>
            </tr>
            <tr>
              <td className="purchase-summary-table__title">
                Shipping And Handtrng
              </td>
              <td className="purchase-summary-table__text">Free Shipping</td>
            </tr>
            <tr>
              <td className="purchase-summary-table__title">Order Total</td>
              <td className="purchase-summary-table__text">$0000,00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  );
}
