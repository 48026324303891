import styled from "styled-components";
import { Swiper } from "swiper/react";

import nextArrow from "../../assets/icons/slider-next-arrow.png";
import previousArrow from "../../assets/icons/slider-previous-arrow.png";

export const StyledSwiper = styled(Swiper)`
  z-index: 0;

  .swiper-pagination {
    padding-bottom: 1rem;
  }

  .swiper-pagination-bullet {
    width: 1.2rem;
    height: 1.2rem;
    background: #fff;
    opacity: 0.5;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 7rem;
    height: 7rem;

    opacity: 0.3;

    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      display: none;
    }
  }

  .swiper-button-prev::after {
    content: url(${previousArrow});
  }

  .swiper-button-next::after {
    content: url(${nextArrow});
  }
`;

export const Img = styled.img`
  width: 100%;
  max-height: 670px;
  object-fit: cover;
`;
