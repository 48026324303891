import { Link } from "react-router-dom";

import styled, { css } from "styled-components";

interface StyledLinkProps {
  $isHighlighted: boolean;
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  display: block;

  color: var(--app-purple) !important;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  text-align: center;
  background: #fff;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  .next-events-section-card__header {
    padding: 3rem 1.5rem;

    ${props =>
      props.$isHighlighted &&
      css`
        background: var(--app-yellow);
      `}

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .next-events-section-card__date {
    font-size: 2.7rem;
  }

  .next-events-section-card__year {
    font-size: 1.4rem;
  }

  .next-events-section-card__footer {
    font-size: 1.5rem;
    background: var(--app-yellow);
    padding: 1.5rem;
  }
`;
