import { Fragment, useContext, useRef } from "react";
import { MdMenu } from "react-icons/md";
import { Link } from "react-router-dom";

import { Container as MuiContainer } from "@material-ui/core";

import { AccountDropdown } from "../AccountDropdown";
import { AuthCountext } from "../../contexts/AuthCountext";
import { CartDropdown } from "../CartDropdown";
import { HeaderLinks } from "../HeaderLinks";

import { StyledHeader } from "./styles";

import iconAccount from "../../assets/icons/icon-account.png";
import iconCart from "../../assets/icons/icon-cart.png";
import iconGuide from "../../assets/icons/icon-guide-me.png";
import logoImg from "../../assets/images/logo.png";
import { envPrefix } from "../../utils/envPrefix";

export function Header() {
  const { isAuthenticated, user } = useContext(AuthCountext);
  const cartButtonEl = useRef<HTMLButtonElement>(null);

  return (
    <StyledHeader>
      <MuiContainer maxWidth="xl">
        <nav className="navbar">
          <div className="navbar__left-container">
            <HeaderLinks />
          </div>

          <div className="navbar__logo-container">
            <Link to="/">
              <img className="navbar__logo" src={logoImg} alt="La Quincave" />
            </Link>
          </div>

          <button
            style={{ marginTop: "5px" }}
            className="navbar__link navbar__toggle"
            uk-toggle="target: #main-offcanvas-bar"
          >
            <MdMenu fontSize="5rem" />
            Menu
          </button>

          <div className="navbar__right-container">
            <button className="navbar__link navbar__link--padding-large">
              <img
                className="navbar__link-icon"
                src={iconGuide}
                alt="Guide Me"
              />
              Guide Me
            </button>

            {!isAuthenticated && (
              <Fragment>
                <button type="button" className="navbar__link navbar__link--padding-large">
                  <img className="navbar__link-icon" src={iconAccount} alt="My Account" />
                  My Account
                </button>

                <AccountDropdown />
              </Fragment>
            )}

            {isAuthenticated && (
              <Link to="/user" className="navbar__link navbar__link--padding-large">
                <img className="navbar__link-icon avatar" src={envPrefix + `galeria/${user.avatar}`} alt="My Account" />
                My Account
              </Link>
            )}

            <button
              ref={cartButtonEl}
              className="navbar__link navbar__link--padding-large navbar__link--highlight"
            >
              <img className="navbar__link-icon" src={iconCart} alt="My Cart" />
              My Cart
            </button>

            <CartDropdown buttonRef={cartButtonEl} />
          </div>
        </nav>
      </MuiContainer>

      <div id="main-offcanvas-bar" uk-offcanvas="overlay: true">
        <div className="uk-offcanvas-bar main-offcanvas-bar">
          <div className="main-offcanvas-bar__container">
            <Link to="/" className="navbar__link">
              Home
            </Link>
            <HeaderLinks />
          </div>

          <hr />

          <div className="main-offcanvas-bar__container">
            <Link
              to="/"
              className="navbar__link navbar__link--padding-large navbar__link--highlight"
            >
              <img className="navbar__link-icon" src={iconCart} alt="My Cart" />
              My Cart
            </Link>
            <Link to="/" className="navbar__link navbar__link--padding-large">
              <img
                className="navbar__link-icon"
                src={iconAccount}
                alt="My Account"
              />
              My Account
            </Link>
            <Link to="/" className="navbar__link navbar__link--padding-large">
              <img
                className="navbar__link-icon"
                src={iconGuide}
                alt="Guide Me"
              />
              Guide Me
            </Link>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
}
