import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import { Container as MuiContainer } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { NextEventsSectionCard } from "../../components/NextEventsSectionCard";
import { SliderSection } from "../../components/SliderSection";

import { api } from "../../services/api";

import {
  Header,
  EventsSliderSection,
  EventsVideoSection,
  EventsAttraction,
  EventsPromoBanner,
} from "./styles";

import attractionImg from "../../assets/temp/events-attraction.png";
import headerSlide from "../../assets/temp/events-header-slide.png";
import promoBanner from "../../assets/temp/promo-banner.png";

export function Events() {
  const { data: nextEvents, isLoading } = useQuery("NextEvents", async () => {
    const response = await api.get("/events/next/all.php");
    console.log(response.data);
  });

  return (
    <main>
      <Helmet>
        <title>La Quincave | Events</title>
      </Helmet>

      <Header data-uk-slider>
        <ul className="events-header-slider uk-slider-items">
          {Array(5).fill(null).map((_, index) => (
            <li className="events-header-slider__item" key={index}>
              <div className="events-header-slider__countdown-container">
                <div className="events-header-slider__wrapper">
                  <h1 className="events-header-slider__title">Next Events</h1>
                  <div className="events-header-countdown-container">
                    <div className="events-header-countdown">
                      <span className="events-header-countdown__number">
                        08
                      </span>
                      <span className="events-header-countdown__text">
                        Months
                      </span>
                    </div>
                    <div className="events-header-countdown">
                      <span className="events-header-countdown__number">
                        24
                      </span>
                      <span className="events-header-countdown__text">
                        Days
                      </span>
                    </div>
                    <div className="events-header-countdown">
                      <span className="events-header-countdown__number">
                        19
                      </span>
                      <span className="events-header-countdown__text">
                        Hours
                      </span>
                    </div>
                    <div className="events-header-countdown">
                      <span className="events-header-countdown__number">
                        37
                      </span>
                      <span className="events-header-countdown__text">
                        Minutes
                      </span>
                    </div>
                    <div className="events-header-countdown">
                      <span className="events-header-countdown__number">
                        56
                      </span>
                      <span className="events-header-countdown__text">
                        Seconds
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="events-header-slider__image-wrapper">
                <img
                  className="events-header-slider__image"
                  src={headerSlide}
                  alt="[TITLE]"
                />
              </div>
            </li>
          ))}
        </ul>
        <ul className="events-header-slider-dotnav uk-slider-nav uk-dotnav" />
        <div
          className="events-header-slider-navigation"
          data-uk-slider-item="previous"
        >
          <ChevronLeftIcon />
        </div>
        <div
          className="events-header-slider-navigation"
          data-uk-slider-item="next"
        >
          <ChevronRightIcon />
        </div>
      </Header>

      <EventsSliderSection>
        <div className="events-slider-section__container uk-box-shadow-small">
          <div
            className="next-events-section__slider-container uk-light"
            data-uk-slider
          >
            <h1 className="next-events-section__slider-side-title">
              March, 2021
            </h1>
            <div className="uk-position-relative">
              <div className="uk-slider-container">
                <ul className="uk-slider-items uk-grid-small">
                  {Array(5).fill(null).map((_, index) => (
                    <li
                      className="uk-width-1-2 uk-width-1-3@m uk-width-1-4@l"
                      key={index}
                    >
                      <NextEventsSectionCard index={index} />
                    </li>
                  ))}
                </ul>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="uk-position-center-left-out"
                data-uk-slidenav-previous
                data-uk-slider-item="previous"
              />
              <div
                style={{ cursor: "pointer" }}
                className="uk-position-center-right-out"
                data-uk-slidenav-next
                data-uk-slider-item="next"
              />
            </div>
          </div>
        </div>
      </EventsSliderSection>

      <EventsVideoSection>
        <MuiContainer maxWidth="xl">
          <div className="events-video-section__container">
            <div className="events-video-section__content-wrapper">
              <h2 className="events-video-section__subtitle">
                Lorem ipsum dolor sit amet, consectetur adipiscing
              </h2>
              <h1 className="events-video-section__title">
                Lorem ipsum dolor sit amet
              </h1>
              <p className="events-video-section__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud.
              </p>
            </div>

            <div className="player-wrapper">
              <ReactPlayer
                width="100%"
                height="100%"
                muted
                playing
                loop
                controls
                url="https://www.youtube.com/watch?v=Rc4LqYM65As"
              />
            </div>
          </div>
        </MuiContainer>
      </EventsVideoSection>

      <EventsAttraction>
        <MuiContainer maxWidth="xl">
          <div className="events-attraction__container">
            <div className="events-attraction__grid">
              <div>
                <img src={attractionImg} alt="[TITLE]" />
              </div>
              <div className="events-attraction__content">
                <h1 className="events-attraction__title">
                  Attraction or novelty
                </h1>
                <article className="events-attraction-article">
                  <h1 className="events-attraction-article__title">
                    Lorem ipsum dolor sit amet
                  </h1>
                  <p className="events-attraction-article__text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                  </p>
                </article>
                <article className="events-attraction-article">
                  <h1 className="events-attraction-article__title">
                    Lorem ipsum dolor sit amet
                  </h1>
                  <p className="events-attraction-article__text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                  </p>
                </article>
                <article className="events-attraction-article">
                  <h1 className="events-attraction-article__title">
                    Lorem ipsum dolor sit amet
                  </h1>
                  <p className="events-attraction-article__text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                  </p>
                </article>
              </div>
            </div>
          </div>
        </MuiContainer>
      </EventsAttraction>

      <SliderSection
        title="Past Events"
        subtitle="Lorem ipsum dolor sit amet"
        route="/past-events"
      />

      <EventsPromoBanner>
        <MuiContainer maxWidth="xl">
          <div className="events-promo-banner__container">
            <Link to="/events">
              <img src={promoBanner} alt="[PROMOTION TITLE]" />
            </Link>
          </div>
        </MuiContainer>
      </EventsPromoBanner>
    </main>
  );
}
