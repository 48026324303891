import { useQuery } from 'react-query';
import { Link } from "react-router-dom";

import { api } from '../../services/api';
import { envPrefix } from '../../utils/envPrefix';

import { Container } from "./styles";

import arrowRight from "../../assets/icons/icon-arrow-right-red.png";
import { Skeleton } from '@material-ui/lab';
import { Fragment } from 'react';

interface SliderSectionProps {
  title: string;
  subtitle: string;
  route: string;
}

interface SliderSectionData {
  id: string;
  title: string;
  slug: string;
  img: string;
}

export function SliderSection({ title, subtitle, route }: SliderSectionProps) {
  const { data, isLoading } = useQuery("SliderSection", async () => {
    const response = await api.get<SliderSectionData[]>("/wineries/all.php");
    return response.data;
  });

  return (
    <Container>
      {isLoading && (
        <Skeleton variant="rect" width="100%" height="500px" />
      )}

      {!isLoading && (
        <Fragment>
          <div className="slider-section__content-wrapper">
            <div>
              <h1 className="slider-section__title">{title}</h1>
              <span className="slider-section__text">{subtitle}</span>
            </div>
          </div>

          <div data-tabindex="-1" data-uk-slider="autoplay: true; autoplay-interval: 6000">
            <ul className="uk-slider-items uk-grid-small" data-uk-grid>
              {data.map(data => (
                <li className="slide" key={data.id}>
                  <Link to={`/winery/${data.slug}`} className="slide__wrapper">
                    <img
                      className="slide__image"
                      src={envPrefix + `galeria/${data.img}`}
                      alt={data.title}
                    />
                    <div className="slide__content">
                      <h2 className="slide__title">{data.title}</h2>
                      <div className="slide__button">Know more</div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>

            <div className="slider-section__link-wrapper">
              <Link to={route} className="slider-section__link">
                explore more
                <img
                  className="uk-margin-small-left"
                  src={arrowRight}
                  alt="Explore more"
                />
              </Link>
            </div>
          </div>
        </Fragment>
      )}
    </Container>
  );
}
