import { StyledLink } from "./styles";

interface NextEventsSectionCardProps {
  index: number;
}

export function NextEventsSectionCard({ index }: NextEventsSectionCardProps) {
  return (
    <StyledLink to="/" $isHighlighted={index === 0}>
      <div className="next-events-section-card__header">
        <span className="next-events-section-card__date">01/12</span>
        <span className="next-events-section-card__year">2020</span>
      </div>
      <div className="next-events-section-card__footer">15h</div>
    </StyledLink>
  );
}
