import styled from "styled-components";

import leafBg1 from "../../assets/images/wine-box/leaf-bg-1.png";
import leafBg2 from "../../assets/images/wine-box/leaf-bg-2.png";

export const Header = styled.header`
  padding-top: 2rem;
`;

export const ContentSection = styled.section`
  padding: 8rem 0;

  .wine-box-content__container {
    position: relative;
    z-index: 0;
    padding: 0 145px;

    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    &::before {
      display: block;
      position: absolute;
      content: url(${leafBg1});
      top: 50%;
      right: calc(100% - 135px);
      z-index: -1;

      transform: translateY(-50%);
    }

    &::after {
      display: block;
      position: absolute;
      content: url(${leafBg2});
      top: 50%;
      left: calc(100% - 135px);
      z-index: -1;
      transform: translateY(-50%);
    }

    ${props => props.theme.breakpoints.down("sm")} {
      padding: 0 3rem;

      &::before,
      &::after {
        display: none;
      }
    }

    ${props => props.theme.breakpoints.down("xs")} {
      padding: 0 1.5rem;
    }
  }

  .wine-box-content__text-wrapper {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    text-align: center;
  }

  .wine-box-content__title {
    color: #c1b2b7;
    font-size: 4.2rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: Bold;
    text-transform: uppercase;
    margin-bottom: 6rem;
  }

  .wine-box-content__text {
    * {
      color: #707070;
      font-size: 1.8rem;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      line-height: 3.3rem;
      margin: 0;
    }
  }

  .wine-box-card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 6rem;
  }

  /*****************************************************************************
  * Wine Box Slider
  *****************************************************************************/

  .wine-box-slider {
    position: relative;
  }

  .wine-box-slider-container {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    margin-top: 15rem;

    ${props => props.theme.breakpoints.down("sm")} {
      flex-direction: column;
      margin-top: 6rem;
    }
  }

  .wine-box-slider-container__illustration-wrapper {
    flex: 1;

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: 380px;
      margin-bottom: 3rem;
    }
  }

  .wine-box-slider {
    flex: 2;
  }

  .wine-box-slider__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .wine-box-slider__arrows-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50%;
    max-width: 1020px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    pointer-events: none;
  }

  .wine-box-slider__arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 7%;

    cursor: pointer;
    pointer-events: all;
  }

  .wine-box-slider__arrow--previous {
    background: #550057;
  }

  .wine-box-slider__arrow--next {
    background: #ffd058;
  }
`;

export const SelectionOfTheWeek = styled.section`
  padding: 8rem 0;

  .wine-box-selection__container {
    width: 100%;
    max-width: 1550px;
    margin: 0 auto;
  }

  .wine-box-selection__title {
    color: #c1b2b7;
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 4rem;
  }

  .wine-box-selection__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 2rem;
    row-gap: 3rem;
  }
`;
