import { Helmet } from "react-helmet-async";

import { Box, Container as MuiContainer } from "@material-ui/core";

import contentImage from "../../assets/images/about/content-image.png";
import headerImage from "../../assets/images/about/header-image.png";
import { BlogSection } from "../../components/BlogSection";
import { CollectionSection } from "../../components/CollectionSection";
import { CustomSlider } from "../../components/CustomSlider";
import { NaturalWineSection } from "../../components/NaturalWineSection";
import { SliderSection } from "../../components/SliderSection";
import { VideoSection } from "../../components/VideoSection";
import { Header, AboutContent } from "./styles";

export function About() {
  return (
    <main>
      <Helmet>
        <title>Laquincave | About</title>
      </Helmet>

      <Header>
        <MuiContainer maxWidth="xl">
          <div className="about-header__container">
            <div className="about-header__text-wrapper">
              <h1 className="about-header__title">
                At vero eos et accusamus et iusto odio dignissimos qui
                blanditiis praesentium voluptatum
              </h1>
              <p className="about-header__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
            <img
              className="about-header__image"
              src={headerImage}
              alt="La Quincave"
            />
          </div>
        </MuiContainer>
      </Header>

      <AboutContent>
        <div className="about-content__image-content-wrapper">
          <div className="about-content__image-container">
            <img
              className="about-content__image"
              src={contentImage}
              alt="La Quincave"
            />

            <div className="about-content__text-wrapper">
              <h1 className="about-content__title">
                At vero eos et accusamus et iusto odio dignissimos qui
                blanditiis praesentium voluptatum.
              </h1>
              <p className="about-content__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
        </div>

        <MuiContainer maxWidth="xl">
          <div className="about-content__text-grid">
            <div className="about-content__text-wrapper">
              <p className="about-content__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p className="about-content__text">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate
                non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                rerum facilis est et expedita distinctio. Nam libero tempore,
                cum soluta nobis est eligendi optio cumque nihil impedit quo
                minus id quod maxime placeat facere possimus.
              </p>
            </div>

            <div className="about-content__text-wrapper">
              <p className="about-content__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p className="about-content__text">
                omnis voluptas assumenda est, omnis dolor repellendus.
                Temporibus autem quibusdam et aut officiis debitis aut rerum
                necessitatibus saepe eveniet ut et voluptates repudiandae sint
                et molestiae non recusandae. Itaque earum rerum hic tenetur a
                sapiente delectus, ut aut reiciendis voluptatibus maiores alias
                consequatur aut perferendis doloribus asperiores repellat.
              </p>
            </div>
          </div>
        </MuiContainer>

        <CustomSlider images={[]} />
      </AboutContent>

      <VideoSection />

      <CollectionSection />

      <SliderSection
        title="Wineries"
        subtitle="Meet our winemakers"
        route="/wineries"
      />

      <NaturalWineSection />

      <Box paddingBottom="5rem">
        <BlogSection />
      </Box>
    </main>
  );
}
