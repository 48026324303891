import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { useMediaQuery, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { SwiperSlide } from "swiper/react";

import { api } from "../../services/api";
import { envPrefix } from "../../utils/envPrefix";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import { StyledSwiper, Img } from "./styles";

interface SliderProps {
  apiRoute: string;
}

interface Banner {
  id: string;
  title: string;
  img: string;
  link: string;
}

interface Banners {
  desktop: Banner[];
  mobile: Banner[];
}

SwiperCore.use([Pagination, Navigation, Autoplay]);

export function Slider({ apiRoute }: SliderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: banners, isLoading } = useQuery<Banners>('banners', async () => {
    const response = await api.get(apiRoute);
    const banners = response.data;

    return banners;
  });

  return (
    <StyledSwiper
      tag="section"
      pagination={{ clickable: true }}
      navigation
      autoplay={{ disableOnInteraction: false, delay: 6000 }}
      speed={1000}
      loop
    >
      {isLoading && (
        <Skeleton
          variant="rect"
          component="div"
          width="100%"
          height={670}
          animation="wave"
        />
      )}

      {!isLoading && isMobile && (
        banners.mobile.map(banner => (
          <SwiperSlide key={banner.id}>
            <Link to={{ pathname: banner.link }}>
              <Img
                src={`${envPrefix}galeria/${banner.img}`}
                alt={banner.title}
              />
            </Link>
          </SwiperSlide>
        ))
      )}

      {!isLoading && !isMobile && (
        banners.desktop.map(banner => (
          <SwiperSlide key={banner.id}>
            <Link to={{ pathname: banner.link }}>
              <Img
                src={`${envPrefix}galeria/${banner.img}`}
                alt={banner.title}
              />
            </Link>
          </SwiperSlide>
        ))
      )}
    </StyledSwiper>
  );
}
