import { Fragment } from "react";
import { useQuery } from 'react-query';
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { Box, Container as MuiContainer } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DOMPurify from "dompurify";


import { BlogSection } from "../../components/BlogSection";
import { CollectionSection } from "../../components/CollectionSection";
import { NaturalWineSection } from "../../components/NaturalWineSection";
import { Newsletter } from "../../components/Newsletter";
import { NextEventsSection } from "../../components/NextEventsSection";
import { Slider } from "../../components/Slider";
import { SliderSection } from "../../components/SliderSection";
import { Testimonials } from "../../components/Testimonials";
import { VideoSection } from "../../components/VideoSection";

import { api } from "../../services/api";
import { envPrefix } from '../../utils/envPrefix';

import { HomeAbout, ShopHours, HomePromo } from "./styles";

import aboutContentImg from "../../assets/images/home/home-about-content-image.png";
import homePromoImg from "../../assets/images/home/home-promo.png";

interface Card {
  id: string;
  title: string;
  img: string;
  link: string;
}

interface Description {
  title: string;
  text: string;
}

interface ShopHour {
  id: string;
  day: string;
  hours: string;
}

export function Home() {
  const cards = useQuery('homeCards', async () => {
    const response = await api.get<Card[]>("/home/cards.php");
    return response.data;
  });

  const description = useQuery('homeDescription', async () => {
    const response = await api.get<Description>("/home/description.php");
    return response.data;
  });

  const shopHours = useQuery('shopHours', async () => {
    const response = await api.get<ShopHour[]>("/shop-hours/all.php");
    return response.data;
  });

  return (
    <main>
      <Helmet>
        <title>La Quincave</title>
      </Helmet>

      <Slider apiRoute="/home/banners.php" />

      <HomeAbout>
        <MuiContainer maxWidth="xl">
          <div className="home-about__cards-container">
            {cards.isLoading && (
              <Skeleton variant="rect" width="100%" height="150px" />
            )}
            {!cards.isLoading && (
              cards.data.map(card => (
                <Link to={{ pathname: card.link }} key={card.id}>
                  <img
                    src={`${envPrefix}galeria/${card.img}`}
                    alt={card.title}
                  />
                </Link>
              ))
            )}
          </div>
        </MuiContainer>

        <MuiContainer className="home-about-content" maxWidth="xl">
          <div className="home-about-content__container">
            <div className="home-about-content__wrapper">
              {description.isLoading && (
                <Fragment>
                  <Skeleton variant="text" height="150px" />
                  <Skeleton variant="text" height="150px" />
                </Fragment>
              )}

              {!description.isLoading && (
                <Fragment>
                  <h1 className="home-about-content__title">{description.data.title}</h1>
                  <div
                    className="home-about-content__text"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description.data.text), }}
                  />
                </Fragment>
              )}

              <Link to={{ pathname: "/about" }} className="home-about-content__button">
                Know more
              </Link>
            </div>

            <img className="home-about-content__image" src={aboutContentImg} alt="La Quincave" />
          </div>
        </MuiContainer>
      </HomeAbout>

      <ShopHours>
        <MuiContainer maxWidth="xl">
          <div className="shop-hours__container">
            <div className="shop-hours__list-wrapper">
              <ul className="shop-hours-list">
                <h1 className="shop-hours__title">Shop Hours</h1>
                {shopHours.isLoading && (
                  Array(7).fill(null).map((_, index) => (
                    <Box marginTop="2rem" key={index}>
                      <Skeleton variant="rect" width="100%" height={20} />
                    </Box>
                  ))
                )}
                {!shopHours.isLoading && (
                  shopHours.data.map(shopHour => (
                    <li className="shop-hours-list__item" key={shopHour.id}>
                      <span className="shop-hours-list__day">
                        {shopHour.day}
                      </span>
                      <span className="shop-hours-list__hours">
                        {shopHour.hours}
                      </span>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </MuiContainer>
      </ShopHours>

      <NextEventsSection />

      <Newsletter />

      <CollectionSection />

      <HomePromo>
        <MuiContainer maxWidth="xl">
          <div className="home-promo__container">
            <img src={homePromoImg} alt="Promo" />

            <div className="home-promo__content-wrapper">
              <h1 className="home-promo__title">
                Lorem Ipsum Dolor Sit amet consectetur
              </h1>
              <p className="home-promo__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices...
              </p>
              <Link to={{ pathname: "/" }} className="home-promo__button">
                Check the promo
              </Link>
            </div>
          </div>
        </MuiContainer>
      </HomePromo>

      <SliderSection
        title="Wineries"
        subtitle="Meet our winemakers"
        route="/wineries"
      />

      <NaturalWineSection />

      <Testimonials />

      <VideoSection />

      <Box paddingBottom="5rem">
        <BlogSection />
      </Box>
    </main>
  );
}
