import styled from "styled-components";

export const Container = styled.div`
  .products-table {
    background: #fff;
    margin-bottom: 0;
  }

  .products-table__thead {
    background: #3eb969;
    border: 1px solid #76cb92;
    border-top: 0;
    border-bottom: 0;

    .products-table__th {
      color: #fff;
      font-size: 1.6rem;
      font-family: "Poppins", sans-serif;
      font-weight: bold;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .products-table__tbody {
    border: 1px solid #e8eaec;
    border-top: 0;

    .products-table__td {
      vertical-align: middle;
    }
  }

  .products-table__tbody-row {
    &:nth-child(even) {
      background: #f0f3f2;
    }
  }

  .products-table__product-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;

    ${props => props.theme.breakpoints.down("xs")} {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .products-table__image {
    width: 70px;
    height: 70px;

    object-fit: scale-down;
  }

  .products-table__product-title {
    color: #550057;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .products-table__quantity {
    color: #5f727f;
    text-align: center;
  }

  .products-table__price {
    color: #99a9b5;
    font-size: 1.8rem;
  }

  /*****************************************************************************
  * Cart Product Table Footer
  *****************************************************************************/

  .products-table-footer {
    background: #fff;
    padding: 2rem;

    border: 1px solid #e8eaec;
    border-top: 0;

    display: flex;
    justify-content: space-between;
    column-gap: 1.5rem;

    ${props => props.theme.breakpoints.down("md")} {
      flex-direction: column;
    }
  }

  .products-table-footer__checkout-wrapper {
    flex: 1;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    row-gap: 1rem;

    ${props => props.theme.breakpoints.down("md")} {
      order: 1;
    }
  }

  .products-table-footer__actions-container {
    display: flex;
    column-gap: 1.5rem;

    ${props => props.theme.breakpoints.down("md")} {
      margin-top: 1rem;
    }

    ${props => props.theme.breakpoints.down(750)} {
      flex-direction: column;
    }
  }

  .products-table-footer__form {
    flex: 1;
    max-width: 400px;

    display: flex;
    column-gap: 1.5rem;
  }

  .products-table-footer__input-coupon {
    width: 100%;
    max-width: 300px;
    padding: 0 2rem;
    background: #f5f7f6;
    border: 1px solid #dfe5e8;
    border-radius: 3px;

    color: #262626;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;

    &::placeholder {
      color: #99a9b5;
    }
  }

  .products-table-footer__apply-button {
    color: var(--app-purple);
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

    width: 100px;
    background: none;
    border: 1px solid var(--app-purple);
    padding: 5px;

    cursor: pointer;
  }

  .products-table-footer__button-continue {
    width: 100%;
    height: 40px;
    max-width: 250px;
    margin: 0 auto;

    color: #fff;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.5px;

    background: #3eb969;
    border: none;
    border-radius: 1rem;
    padding: 0 1rem;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: transform 0.2s;

    &:hover {
      transform: translateY(-3px);
    }

    ${props => props.theme.breakpoints.down("md")} {
      margin-left: auto;
      margin-right: 0;
    }

    ${props => props.theme.breakpoints.down(750)} {
      max-width: unset;
      margin-top: 3rem;
    }
  }

  /*****************************************************************************
  * Purchase Summary Table
  *****************************************************************************/

  .purchase-summary-table {
    align-self: flex-end;

    margin: 0;
    width: max-content;

    white-space: nowrap;

    tr + tr {
      border-top: 1px solid #dfe5e8;
    }

    ${props => props.theme.breakpoints.down("md")} {
      order: 0;
      width: 100%;
      margin-bottom: 2rem;
    }

    ${props => props.theme.breakpoints.down(450)} {
      td {
        display: block;
        width: auto;
        max-width: none;
        min-width: 0;
        overflow: visible;
        white-space: normal;

        text-align: center;
      }
    }
  }

  .purchase-summary-table__title {
    color: #5f727f;
  }

  .purchase-summary-table__text {
    color: #3eb969;
    font-size: 1.8rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    ${props => props.theme.breakpoints.down("md")} {
      text-align: right;
    }
  }
`;
