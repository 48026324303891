import {
  createContext,
  ReactNode,
  useState,
  useEffect,
} from "react";

import { useQuery } from 'react-query';
import { toast } from "react-toastify";

import { api } from "../services/api";
import { Product } from "../types/product";

interface CartProduct extends Product {
  quantity: number;
}

interface CartProviderProps {
  children: ReactNode;
}

interface CartContextData {
  cart: CartProduct[];
  cartTotal: number;
  addProduct: (product: CartProduct) => void;
  removeProduct: (id: string) => void;
  decreaseProductQuantity: (id: string) => void;
  increaseProductQuantity: (id: string) => void;
}

export const CartContext = createContext({} as CartContextData);

export function CartProvider({ children }: CartProviderProps) {
  const [cartTotal, setCartTotal] = useState(0);
  const [cart, setCart] = useState<CartProduct[]>(() => {
    const storagedCart = localStorage.getItem("@LaQuincave:cart");

    if (storagedCart) {
      return JSON.parse(storagedCart);
    }

    return [];
  });

  useQuery('cart', async () => {
    const response = await api.get("/products/cart.php", {
      params: { products: cart },
    });

    setCart(response.data);
  }, {
    refetchInterval: 1000 * 60 // 60 seconds
  });

  useEffect(() => {
    const total = cart.reduce((acc, product) => {
      return acc + product.quantity * Number(product.price);
    }, 0);

    setCartTotal(total);

    localStorage.setItem("@LaQuincave:cart", JSON.stringify(cart));
  }, [cart]);

  function addProduct(product: CartProduct) {
    try {
      if (product.quantity <= 0) {
        throw new Error("Unspecified quantity");
      }

      const updatedCart = [...cart];
      const productAlredyInCart = updatedCart.find(
        productInCart => productInCart.id === product.id
      );

      if (productAlredyInCart) {
        productAlredyInCart.quantity += product.quantity;

        setCart(updatedCart);

        toast.success("The product has been added");

        return;
      }

      setCart([product, ...cart]);

      toast.success("The product has been added");
    } catch (error) {
      toast.error(error.message);
    }
  }

  function removeProduct(id: string) {
    const updatedcart = cart.filter(product => product.id !== id);
    setCart(updatedcart);
  }

  function decreaseProductQuantity(id: string) {
    const updatedCart = [...cart];

    const product = updatedCart.find(product => product.id === id);

    if (product && product.quantity > 1) {
      product.quantity -= 1;
      setCart(updatedCart);
    }
  }

  function increaseProductQuantity(id: string) {
    const updatedCart = [...cart];

    const product = updatedCart.find(product => product.id === id);

    if (product) {
      product.quantity += 1;
      setCart(updatedCart);
    }
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        cartTotal,
        addProduct,
        removeProduct,
        decreaseProductQuantity,
        increaseProductQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
