import styled from "styled-components";

import shapeImg from "../../assets/images/header/header-shape.png";

export const StyledHeader = styled.header`
  background: #fff;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 40px;
    background: url(${shapeImg}) repeat center bottom;
    position: absolute;
    top: calc(100% - 1px);
  }

  /*****************************************************************************
  * Navbar
  *****************************************************************************/

  .navbar {
    width: 100%;
    max-width: 1630px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    column-gap: 3rem;
    row-gap: 0.5rem;

    ${props => props.theme.breakpoints.down("sm")} {
      flex-direction: column;
      align-items: center;
    }
  }

  .navbar__link {
    color: #434343;
    background: none;
    border: none;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    padding: 0.5rem;
    white-space: nowrap;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
  }

  .navbar__link--padding-large {
    padding: 1rem;
  }

  .navbar__link--highlight {
    background: var(--app-yellow);
    border-radius: 0 0 4px 4px;
  }

  .navbar__toggle {
    display: none;

    ${props => props.theme.breakpoints.down("md")} {
      display: flex;
    }
  }

  .navbar__left-container {
    width: 100%;
    max-width: 570px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;

    ${props => props.theme.breakpoints.down("md")} {
      display: none;
    }
  }

  .navbar__logo-container {
    display: flex;
    align-items: center;

    ${props => props.theme.breakpoints.down("md")} {
      width: 100%;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      justify-content: center;
    }
  }

  .navbar__logo {
    width: 100%;
    min-width: 23rem;
    max-width: 26rem;
    margin: 1.5rem 0;
  }

  .navbar__right-container {
    width: 100%;
    max-width: 500px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;

    ${props => props.theme.breakpoints.down("sm")} {
      display: none;
    }
  }

  .navbar__link-icon {
    width: 5rem;
    height: 5rem;
    object-fit: scale-down;

    &.avatar {
      object-fit: cover;
      border-radius: 50%;
    }
  }

  /*****************************************************************************
  * Main Offcanvas Bar
  *****************************************************************************/

  .main-offcanvas-bar {
    position: relative;
    height: 100%;
    background: #fff;
    overflow: visible;

    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      color: #434343 !important;
    }

    hr {
      border-top-color: #929292;
    }
  }

  .main-offcanvas-bar__container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
`;
