import styled, { css } from "styled-components";

import eventSideBg from "../../assets/images/next-events-section/event-side-bg.jpg";
import sliderBg from "../../assets/images/next-events-section/slider-bg.png";

interface ContainerProps {
  purple: boolean;
}

export const Container = styled.section<ContainerProps>`
  padding: 6rem 0;

  ${props =>
    props.purple &&
    css`
      background: var(--app-purple);
      border: 10px solid #630058;
      border-left: 0;
      border-right: 0;
      padding: 0;
    `}

  .next-events-section__container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: minmax(auto, 50%) 1fr;

    ${props => props.theme.breakpoints.down("md")} {
      max-width: 640px;

      display: flex;
      flex-direction: column;
    }
  }

  /*******************************************************************************
  * Next Events Section Slider Side
  *******************************************************************************/

  .next-events-section__slider-side {
    background: url(${sliderBg}) no-repeat right top / cover;
    padding: 3rem 2rem;
  }

  .next-events-section__slider-side-title {
    color: #fff;
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 4rem;
  }

  .next-events-section__slider-container {
    position: relative;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;

    .uk-grid-small {
      margin-left: -15px;
    }

    ${props => props.theme.breakpoints.down("xs")} {
      max-width: 230px;
    }
  }

  .next-events-section__schedule-button {
    display: flex;
    align-items: center;

    width: 100%;
    max-width: max-content;

    background: #fff;
    padding: 0.5rem 0.6rem;
    margin: 0 auto;
    margin-top: 2.5rem;

    color: #707070;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

    img {
      margin-left: 1.5rem;
    }

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  /*******************************************************************************
  * Next Events Section Event Side
  *******************************************************************************/

  .next-events-section__event-side {
    background: url(${eventSideBg}) no-repeat right center / cover;
  }

  .next-events-section__event-side-content {
    width: 100%;
    height: 100%;
    min-height: 350px;
    max-width: 320px;
    background: var(--app-yellow);
    padding: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .next-events-section__event-side-title {
    color: var(--app-red);
    font-size: 2.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin: 0;
  }

  .next-events-section__event-side-text {
    color: #262626;
    font-family: "Poppins", "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    margin: 3rem 0 2.5rem 0;
  }

  .next-events-section__event-side-button {
    display: block;
    margin-right: auto;
    padding: 1.4rem;

    color: var(--app-purple);
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 1.4rem;
    text-align: center;

    border: 1px solid var(--app-purple);
    border-radius: 10px;

    width: 100%;
    max-width: 160px;

    transition: all 0.2s;

    &:hover {
      background: var(--app-purple);
      color: #fff;
    }
  }
`;
