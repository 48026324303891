import styled from "styled-components";

import headerBg from "../../assets/images/about/header-bg.png";

export const Header = styled.header`
  padding-top: 6rem;
  padding-bottom: 3rem;
  background: url(${headerBg}) no-repeat center bottom / cover;

  .about-header__container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7rem;
    row-gap: 3rem;

    ${props => props.theme.breakpoints.down("md")} {
      flex-direction: column;
    }
  }

  .about-header__text-wrapper {
    width: 100%;
    max-width: 610px;

    ${props => props.theme.breakpoints.down("md")} {
      order: 1;
    }
  }

  .about-header__title {
    color: var(--app-yellow);
    font-size: 2.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    line-height: 3.5rem;
  }

  .about-header__text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    line-height: 2.7rem;
  }

  .about-header__image {
    ${props => props.theme.breakpoints.down("md")} {
      order: 0;
    }
  }
`;

export const AboutContent = styled.section`
  padding: 8rem 0;
  margin: 0 auto;
  width: 100%;

  ${props => props.theme.breakpoints.down("md")} {
    max-width: 960px;
  }

  ${props => props.theme.breakpoints.down("sm")} {
    max-width: 640px;
  }

  .about-content__image-content-wrapper {
    width: 100%;
    max-width: 1920px;
    padding-right: 3rem;
    margin: 0 auto;

    ${props => props.theme.breakpoints.down("sm")} {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  .about-content__image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 3rem;

    width: 100%;
    max-width: 1600px;

    ${props => props.theme.breakpoints.down("md")} {
      flex-direction: column;
      row-gap: 6rem;
    }
  }

  .about-content__image {
    ${props => props.theme.breakpoints.down(1400)} {
      max-width: 50%;
    }

    ${props => props.theme.breakpoints.down("md")} {
      max-width: 100%;
    }
  }

  .about-content__text-wrapper {
    width: 100%;
    max-width: 610px;
  }

  .about-content__title {
    color: var(--app-red);
    font-size: 2.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    line-height: 3.5rem;
  }

  .about-content__text {
    color: #707070;
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    line-height: 2.7rem;

    & + .about-content__text {
      margin-top: 4rem;
    }
  }

  .about-content__text-grid {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 8rem;

    display: flex;
    justify-content: space-between;
    column-gap: 3rem;

    ${props => props.theme.breakpoints.down("sm")} {
      flex-direction: column;
      margin-top: 0;
    }
  }

  .about-content__text-wrapper {
    width: 100%;
    max-width: 600px;
  }
`;
