import { Breadcrumbs } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import styled from "styled-components";

import headerBg from "../../assets/images/wine-list/wine-list-header-bg.png";

export const Header = styled.header`
  background: url(${headerBg}) no-repeat center bottom / cover;
  min-height: 400px;
  padding-top: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .wine-list-header__container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.5rem;

    ${props => props.theme.breakpoints.down("md")} {
      max-width: 640px;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      flex-direction: column;
      padding: 6rem 0;
    }
  }

  .wine-list-header__illustration {
    margin-top: auto;

    ${props => props.theme.breakpoints.down("md")} {
      display: none;
    }
  }

  .wine-list-header__bonus {
    color: #faad99;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;

    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .wine-list-header__bonus-number {
    font-size: 18rem;
    padding-left: 2rem;
  }

  .wine-list-header__bonus-text {
    font-size: 7.7rem;
    margin-top: -10rem;
  }

  .wine-list-header__text-container {
    max-width: 230px;
  }

  .wine-list-header__text {
    color: #fff;
    font-size: 1.8rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    display: inline-block;
  }

  .wine-list-header__button {
    color: #fff !important;
    font-size: 1.8rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-transform: unset;
    border: 2px solid #fff;
    padding: 1rem;
    margin-top: 3rem;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const WineListContent = styled.section`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0;

  display: grid;
  grid-template-columns: 400px 1fr;

  ${props => props.theme.breakpoints.down("md")} {
    grid-template-columns: unset;
  }

  /*****************************************************************************
  * Wine List Sidebar Offcanvas
  *****************************************************************************/

  .wine-list-sidebar-offcanvas {
    .uk-offcanvas-bar {
      background: #fff;
      padding: 4rem 1.5rem 0;

      width: 350px;

      ${props => props.theme.breakpoints.down(350)} {
        width: 270px;
      }
    }

    .uk-close {
      color: #262626;
      top: 5px;
      right: 10px;
    }
  }

  /*****************************************************************************
  * Wine List Products
  *****************************************************************************/

  .wine-list-products {
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 8rem 3rem;

    display: flex;
    flex-direction: column;
    row-gap: 6rem;

    ${props => props.theme.breakpoints.down("xs")} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .wine-list-products__title {
    color: #c1b2b7;
    font-size: 7rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;

    ${props => props.theme.breakpoints.down("md")} {
      text-align: center;
    }
  }

  .wine-list-products__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 1.5rem;
    row-gap: 5rem;

    ${props => props.theme.breakpoints.down("md")} {
      justify-content: center;
      flex-direction: column;
    }
  }

  .wine-list-sidebar-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;

    ${props => props.theme.breakpoints.down("md")} {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      align-items: center;

      font-family: "Poppins";
      font-weight: 500;
      font-size: 1.5rem;

      svg {
        width: 5rem;
      }
    }
  }

  .wine-list-products__sort-container {
    width: 100%;
    max-width: 375px;
  }

  .wine-list-products__sort-label {
    display: inline-block;
    color: #bebebe;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }

  .wine-list-products__sort-select {
    width: 100%;

    color: #262628;
    font-size: 1.9rem;

    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(38, 38, 40, 0.1);
    padding-bottom: 1rem;

    cursor: pointer;
  }

  /*****************************************************************************
  * Wine List Products Grid
  *****************************************************************************/

  .wine-list-products__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-template-rows: auto;
    gap: 1.5rem;
    align-items: stretch;

    ${props => props.theme.breakpoints.down(800)} {
      margin: 0 auto;
    }

    ${props => props.theme.breakpoints.down(400)} {
      grid-template-columns: unset;
    }
  }

  /*****************************************************************************
  * Wine List Products Footer
  *****************************************************************************/

  .wine-list-products__footer {
    width: 100%;
    max-width: 870px;
    margin: 0 auto;
    margin-top: auto;
    border-top: 1px solid #eaeaea;
    padding-top: 1.5rem;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    row-gap: 2rem;

    ${props => props.theme.breakpoints.down("xs")} {
      flex-direction: column;
    }
  }

  .wine-list-products__pagination-info {
    display: flex;
    align-items: center;
  }

  .wine-list-products__pagination-select {
    color: #7e7e7e;
    font-size: 1.4rem;
    border-radius: 500px;
    padding: 0.5rem;
    cursor: pointer;
  }

  .wine-list-products__pagination-text {
    color: #717171;
    font-size: 1.3rem;
    margin-left: 1.5rem;
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  color: #333e48;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
`;

export const StyledPagination = styled(Pagination)`
  button {
    color: #7e7e7e;
    font-size: 1.4rem;
    background-color: #fff;
    border: 1px solid #e8e7e7;
    height: 3.5rem;
    min-width: 3.5rem;

    &.Mui-selected {
      color: #fff;
      background-color: var(--app-red) !important;
    }
  }
`;
