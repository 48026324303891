import styled from "styled-components";

import loginBg from "../../assets/images/login/login-bg.png";

export const Container = styled.div`
  flex: 1;
  position: relative;

  min-height: 1000px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, #fff 100%),
    url(${loginBg});

  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mq("m") {
    min-height: unset;
    padding: 6rem 0 8rem 0;
  }

  .login__container {
    width: 100%;
    max-width: 1100px;
    min-height: 700px;
    margin: 0 auto;

    box-shadow: 0px 11px 68px 0px rgba(0, 0, 0, 0.38);

    display: grid;
    grid-template-columns: 1fr 1fr;

    @include mq("m") {
      max-width: 640px;
      grid-template-columns: 1fr;
    }

    @include mq("s") {
      min-height: 600px;
    }
  }

  .login__content-wrapper {
    background: #f8f4f1;
    padding: 3rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login__content-container {
    width: 100%;
    max-width: 350px;
  }

  .login__illustration-wrapper {
    position: relative;
    background: #d73c40;

    display: flex;
    flex-direction: column;
    justify-content: center;

    z-index: 0;

    @include mq("m") {
      display: none;
    }
  }

  .login__illustration-people {
    z-index: 1;
  }

  .login__illustration-cloud-1 {
    position: absolute;
    top: -3rem;
    left: -2rem;
  }

  .login__illustration-cloud-2 {
    position: absolute;
    top: 5rem;
    right: -1.5rem;
  }

  .login__illustration-leaves {
    position: absolute;
    top: 50%;
    right: -6rem;
    transform: translateY(-40%);
    z-index: 0;
  }

  /*****************************************************************************
  * Login Form
  *****************************************************************************/

  .login-form__title {
    font-size: 4.5rem;

    ${props => props.theme.breakpoints.down(360)} {
      font-size: 3.2rem;
    }
  }

  /* .login__form-button-container {
    @include mq(360px) {
      flex-direction: column-reverse;
      row-gap: 10px;
    }
  } */

  /* .login__media-button-text {
    @include mq(360px) {
      margin-left: 1rem;
    }
  } */
`;
