import { useQuery } from 'react-query';
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import { Container as MuiContainer } from "@material-ui/core";
import DOMPurify from "dompurify";

import { api } from "../../services/api";
import { Loading } from "../Loading";
import { StyledVideSection } from "./styles";

interface VideSectionData {
  title: string;
  text: string;
  url: string;
}

export function VideoSection() {
  const { data: content, isLoading } = useQuery("videoSection", async () => {
    const response = await api.get("/video-section/content.php");
    return response.data;
  });

  return (
    <StyledVideSection>
      <MuiContainer maxWidth="xl">
        {isLoading && <Loading color="#fff" />}

        {!isLoading && (
          <div className="video-section__container">
            <div className="video-section__content-wrapper">
              <h1 className="video-section__title">{content.title}</h1>
              <div
                className="video-section__text"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(content.text),
                }}
              />
              <Link to={{ pathname: "/" }} className="video-section__button">
                Create an account
              </Link>
            </div>

            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                width="100%"
                height="100%"
                muted
                playing
                loop
                controls
                url={content.url}
              />
            </div>
          </div>
        )}
      </MuiContainer>
    </StyledVideSection>
  );
}
