import { Helmet } from "react-helmet-async";
import ReactPlayer from "react-player";

import { Box } from "@material-ui/core";

import headerImage from "../../assets/temp/slider-image.png";
import { CustomSlider } from "../../components/CustomSlider";
import { SliderSection } from "../../components/SliderSection";
import { Header, PastEvenstSection, PlayerWrapper } from "./styles";

export function PastEvents() {
  const playerConfig = {
    width: "100%",
    height: "100%",
    muted: true,
    playing: true,
    loop: true,
    url: "https://www.youtube.com/watch?v=Rc4LqYM65As",
  };

  return (
    <main>
      <Helmet>
        <title>La Quincave | Past Events</title>
      </Helmet>

      <Header>
        <div className="app-container">
          <div className="past-events-header__container">
            <div className="past-events-header__content-wrapper">
              <h1 className="past-events-header__title">
                At vero eos et accusamus et iusto odio dignissimos qui
                blanditiispraesentium voluptatum.
              </h1>
              <p className="past-events-header__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
            <div className="past-events-header__image-wrapper">
              <img
                className="past-events-header__image"
                src={headerImage}
                alt="[TITLE]"
              />
            </div>
          </div>
        </div>
      </Header>

      <PastEvenstSection>
        <div className="past-events-section__video-column-wrapper">
          <div className="past-events-section__video-column">
            <PlayerWrapper>
              <ReactPlayer {...playerConfig} />
            </PlayerWrapper>
            <div className="past-events-section__video-column-content">
              <h1 className="past-events-section__title">
                At vero eos et accusamus et iusto odio dignissimos qui
                blanditiis praesentium voluptatum.
              </h1>
              <p className="past-events-section__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>

          <div className="app-container">
            <div className="past-events-section__content-grid">
              <p className="past-events-section__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p className="past-events-section__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p className="past-events-section__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p className="past-events-section__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
        </div>

        <div className="custom-slider-wrapper">
          <CustomSlider images={[]} />
        </div>
      </PastEvenstSection>

      <Box marginBottom="5rem">
        <SliderSection
          title="Past Events"
          subtitle="Lorem ipsum dolor sit amet"
          route="/past-events"
        />
      </Box>
    </main>
  );
}
