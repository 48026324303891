import { LoginForm } from "../LoginForm";

import { Container } from "./styles";

export function AccountDropdown() {
  return (
    <Container data-uk-dropdown="mode: click; pos: bottom-right">
      <LoginForm />
    </Container>
  );
}
