interface PriceFormatProps {
  price: number;
  currency?: string;
}

export function priceFormat({ price, currency = "EUR" }: PriceFormatProps) {
  const formatter = new Intl.NumberFormat([], {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  });

  return formatter.format(price);
}
