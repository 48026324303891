import { Fragment, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

import { Container as MuiContainer } from "@material-ui/core";

import productCardBookImg from "../../assets/temp/product-card-book.png";
import productCardImg from "../../assets/temp/product-card.png";
import featureIcon from "../../assets/temp/wine-box-inner-feature-icon.png";
import productImg from "../../assets/temp/wine-box-inner-product.png";
import slideImg from "../../assets/temp/wine-box-inner-slide.png";
import {
  Container,
  Header,
  ContentSection,
  RatingSection,
  Rating,
} from "./styles";

export function WineBoxInner() {
  const [quantity, setQuantity] = useState(0);
  const [rating, setRating] = useState<number | null>(4);

  function handleDecreaseQuantity() {
    const newQuantity = quantity - 1;

    if (newQuantity >= 0) {
      setQuantity(newQuantity);
    }
  }

  function handleIncreaseQuantity() {
    const newQuantity = quantity + 1;

    if (newQuantity >= 0) {
      setQuantity(newQuantity);
    }
  }

  return (
    <main>
      <Header>
        <div className="header__slider" data-uk-slider>
          <ul className="uk-slider-items">
            {Array(6)
              .fill(null)
              .map((_, index) => (
                <li key={index}>
                  <img src={slideImg} alt="[TITLE]" />
                </li>
              ))}
          </ul>
          <ul className="uk-slider-nav uk-dotnav" />
        </div>

        <div className="header__container-wrapper">
          <div className="header__container">
            <strong className="header__title">Lorem Ipsum Dolor</strong>
            <div className="header__average-rating-container uk-margin-bottom">
              <Rating value={4} readOnly />
              <span className="header__average-rating-text">4 of 5 stars</span>
            </div>
            <span className="header__discount-text">€70,00</span>
            <span className="header__discount-badge">20% off</span>
            <span className="header__price">€ 59,99</span>
            <p className="header__description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.
            </p>

            <div className="header__actions-container">
              <div className="amount-actions">
                <button onClick={handleDecreaseQuantity}>
                  <FaMinus />
                </button>
                <span className="amount">{quantity}</span>
                <button onClick={handleIncreaseQuantity}>
                  <FaPlus />
                </button>
              </div>
              <div className="header__actions-divider" />
              <button className="cart-button">Add to cart</button>
            </div>
          </div>
        </div>
      </Header>

      <ContentSection>
        <MuiContainer maxWidth="xl">
          <Container>
            <h2 className="wine-box-inner__title">Kit itens</h2>
            <div
              className="wine-box-inner-slider"
              data-uk-slider="finite: true"
            >
              <ul className="uk-slider-items uk-grid-small">
                <li>
                  <div className="wine-box-inner-product-card active">
                    <div className="wine-box-inner-product-card__header">
                      <span className="uk-badge">2un.</span>
                      <img src={productCardImg} alt="[TITLE]" />
                    </div>
                    <hr className="product-divider product-divider--red uk-divider-icon" />
                    <span className="wine-box-inner-product-card__text">
                      Lorem ipsum dolor sit amet
                    </span>
                  </div>
                </li>
                {Array(7)
                  .fill(null)
                  .map((_, index) => (
                    <Fragment key={index}>
                      <li>
                        <div className="wine-box-inner-product-card">
                          <div className="wine-box-inner-product-card__header">
                            <span className="uk-badge">1un.</span>
                            <img src={productCardBookImg} alt="[TITLE]" />
                          </div>
                          <hr className="product-divider product-divider--red uk-divider-icon" />
                          <span className="wine-box-inner-product-card__text">
                            Lorem ipsum dolor sit amet
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="wine-box-inner-product-card">
                          <div className="wine-box-inner-product-card__header">
                            <span className="uk-badge">2un.</span>
                            <img src={productCardImg} alt="[TITLE]" />
                          </div>
                          <hr className="product-divider product-divider--red uk-divider-icon" />
                          <span className="wine-box-inner-product-card__text">
                            Lorem ipsum dolor sit amet
                          </span>
                        </div>
                      </li>
                    </Fragment>
                  ))}
              </ul>
            </div>

            <div className="wine-box-inner-feature-grid">
              <div>
                <h2 className="wine-box-inner__title">Features</h2>
                <div className="wine-box-inner-features-grid">
                  {Array(7)
                    .fill(null)
                    .map((_, index) => (
                      <div className="wine-box-inner-feature" key={index}>
                        <img src={featureIcon} alt="[TITLE]" />
                        <div>
                          <span className="wine-box-inner-feature__title">
                            Region
                          </span>
                          <span className="wine-box-inner-feature__description">
                            Languedoc
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <img src={productImg} alt="[TITLE]" />
              </div>
            </div>
          </Container>
        </MuiContainer>
      </ContentSection>

      <RatingSection>
        <MuiContainer maxWidth="xl">
          <Container>
            <header className="rating-section__header">
              <div>
                <h1 className="rating-section__title">
                  User Rating <span className="amount">(100)</span>
                </h1>
                <div className="rating-section__rate-container">
                  <Rating
                    name="user-rating"
                    value={rating}
                    onChange={(_, newValue) => setRating(newValue)}
                  />
                  <span className="rating-section__rate-text">
                    {rating} of 5 stars
                  </span>
                </div>
              </div>
              <span className="rating-section__header-message">
                You must be logged in and have purchased the product to rate it.
              </span>
            </header>
            <div className="rating-section__comments-container">
              <div className="rating-section-comment">
                <img
                  className="rating-section-comment__avatar"
                  src="https://picsum.photos/60"
                  alt="[USERNAME]"
                />
                <div>
                  <span className="rating-section-comment__username">
                    John Doe
                  </span>
                  <p className="rating-section-comment__text">
                    Lorem ipsum dolor sit amet consectetur adiscipli gravitae
                    lorem ipsum dolor sit amet.
                  </p>
                  <div className="rating-section-comment__footer">
                    <time
                      className="rating-section-comment__date"
                      dateTime="2021-06-18 13:47"
                    >
                      18/06/2021
                    </time>
                    <Rating value={4} readOnly />
                  </div>
                </div>
              </div>
              <div className="rating-section-comment">
                <img
                  className="rating-section-comment__avatar"
                  src="https://picsum.photos/60"
                  alt="[USERNAME]"
                />
                <div>
                  <span className="rating-section-comment__username">
                    John Doe
                  </span>
                  <p className="rating-section-comment__text">
                    Lorem ipsum dolor sit amet consectetur adiscipli gravitae
                    lorem ipsum dolor sit amet.
                  </p>
                  <div className="rating-section-comment__footer">
                    <time
                      className="rating-section-comment__date"
                      dateTime="2021-06-18 13:47"
                    >
                      18/06/2021
                    </time>
                    <Rating value={4} readOnly />
                  </div>
                </div>
              </div>
              <div className="rating-section-comment">
                <img
                  className="rating-section-comment__avatar"
                  src="https://picsum.photos/60"
                  alt="[USERNAME]"
                />
                <div className="uk-flex-1">
                  <span className="rating-section-comment__username">
                    John Doe
                  </span>
                  <form className="rating-section-comment__form" action="">
                    <textarea
                      name="user-rating"
                      rows={5}
                      placeholder="Leave a comment"
                    />
                    <button
                      className="rating-section-comment__submit"
                      type="submit"
                    >
                      Comment
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </MuiContainer>
      </RatingSection>
    </main>
  );
}
