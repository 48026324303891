import { Link } from "react-router-dom";

import { Container as MuiContainer } from "@material-ui/core";

import figureImg from "../../assets/images/natural-wine-section/figure.png";
import { Container } from "./styles";

export function NaturalWineSection() {
  return (
    <Container>
      <MuiContainer maxWidth="xl">
        <div className="natural-wine-section__container">
          <img src={figureImg} alt="Natural Wine" />
          <div className="natural-wine-section__content-wrapper">
            <h1 className="natural-wine-section__title">
              Natural Wine: All you need to know
            </h1>
            <p className="natural-wine-section__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud.
            </p>
            <Link to="/" className="natural-wine-section__button">
              Know more
            </Link>
          </div>
        </div>
      </MuiContainer>
    </Container>
  );
}
