import styled, { css } from "styled-components";

interface ContainerProps {
  purple: boolean;
}

export const Container = styled.section<ContainerProps>`
  padding: 4rem 0;
  background: var(--app-red);

  ${props =>
    props.purple &&
    css`
      background: #670a69;
    `}

  .newsletter__container {
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 1.5rem;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;

    ${props => props.theme.breakpoints.down("md")} {
      flex-direction: column;
      text-align: center;
    }
  }

  .newsletter__title {
    color: #fff;
    font-size: 2rem;
    margin: 0;

    display: flex;
    align-items: center;
  }

  .newsletter__title-img {
    margin-right: 1rem;
  }

  .newsletter__text {
    color: #fff;
    font-size: 1.5rem;
  }

  /*****************************************************************************
  * NewsLetter Form
  *****************************************************************************/

  .newsletter-form {
    width: 100%;
    max-width: 460px;

    display: flex;
  }

  .newsletter-form__input {
    flex: 1;
    width: 100%;

    padding: 1.5rem;
    padding-left: 3rem;
    border-radius: 10px 0 0 10px;
    border: none;

    font-size: 1.5rem;
    font-family: "Poppins";

    &::placeholder {
      margin: 0;
    }
  }

  .newsletter-form__submit {
    width: 100%;
    max-width: 10rem;
    padding: 1.5rem;

    color: var(--app-red);

    ${props =>
      props.purple &&
      css`
        color: #670a69;
      `}

    background: #fed700;
    border: none;
    border-radius: 0 10px 10px 0;

    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    white-space: nowrap;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
