import styled from "styled-components";

import leafBg from "../../assets/images/contact/leaf-bg.png";

export const Container = styled.section`
  padding: 0;
  position: relative;

  .contact__container {
    position: relative;
    width: 100%;
    max-width: 770px;
    padding: 20rem 4rem 13rem 4rem;
    margin-left: 10vw;
    background: url(${leafBg}) center center var(--app-red);

    ${props => props.theme.breakpoints.down("md")} {
      margin: 0;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      margin: 0 auto;
    }

    ${props => props.theme.breakpoints.down("xs")} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .contact__wrapper {
    width: 100%;
    max-width: 530px;
    margin: 0 auto;
    margin-bottom: 10rem;
  }

  .contact__title {
    color: var(--app-yellow);
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin: 0;
    margin-bottom: 4rem;
  }

  .contact__text {
    color: #fff;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    margin: 0;
    margin-bottom: 10rem;
  }

  /*****************************************************************************
  * Contact Form
  *****************************************************************************/

  .contact-form {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .contact-form__input-group {
    display: flex;
    row-gap: 2rem;
    column-gap: 1rem;

    ${props => props.theme.breakpoints.down("xs")} {
      flex-direction: column;
    }
  }

  .contact-form__input {
    flex: 1;

    padding: 1.5rem;
    border-radius: 1rem;
    border: none;
    resize: none;

    color: #262626;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;

    &::placeholder {
      color: #a0a0a0;
    }
  }

  .contact-form__button {
    margin-top: 1rem;
    align-self: flex-end;

    background: none;
    cursor: pointer;

    color: #fff !important;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    border: 1px solid #fff;
    border-radius: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 160px;
    padding: 1.3rem;

    transition: transform 0.3s;

    &:hover {
      transform: translateY(-3px);
    }

    ${props => props.theme.breakpoints.down(760)} {
      align-self: center;
    }
  }

  /*****************************************************************************
  * Contact Info
  *****************************************************************************/

  .contact__info-container {
    display: flex;
    justify-content: space-between;
    gap: 3rem;

    ${props => props.theme.breakpoints.down(760)} {
      flex-direction: column;
      text-align: center;
    }
  }

  .contact__info-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .contact__info-title {
    color: var(--app-yellow);
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
  }

  .contact__info-text {
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }
`;

export const Map = styled.iframe`
  width: 100%;
  height: calc(100% + 150px);
  margin-top: -170px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 0;

  ${props => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;
