import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { Container as MuiContainer } from "@material-ui/core";

import sliderIllustration from "../../assets/images/wine-box/slider-container-illustration.png";
import nextArrow from "../../assets/images/wine-box/slider-next-arrow.png";
import previousArrow from "../../assets/images/wine-box/slider-previous-arrow.png";
import sliderImg from "../../assets/temp/wine-box-slider.png";
import selection1 from "../../assets/temp/wine-box-selection-1.png";
import selectionPurple from "../../assets/temp/wine-box-selection-purple.png";
import selectionRed from "../../assets/temp/wine-box-selection-red.png";
import selectionYellow from "../../assets/temp/wine-box-selection-yellow.png";
import card1 from "../../assets/temp/winebox-card-1.png";
import card2 from "../../assets/temp/winebox-card-2.png";
import card3 from "../../assets/temp/winebox-card-3.png";
import { NaturalWineSection } from "../../components/NaturalWineSection";
import { Newsletter } from "../../components/Newsletter";
import { NextEventsSection } from "../../components/NextEventsSection";
import { Slider } from "../../components/Slider";
import { SliderSection } from "../../components/SliderSection";
import { Header, ContentSection, SelectionOfTheWeek } from "./styles";

export function WineBox() {
  return (
    <main>
      <Helmet>
        <title>La Quincave | Wine Box</title>
      </Helmet>

      <Header>
        <Slider apiRoute="/home/getBanners.php" />
      </Header>

      <ContentSection>
        <div className="wine-box-content__container">
          <div className="wine-box-content__text-wrapper">
            <h1 className="wine-box-content__title">LOREM IPSUM DOLOR SIT</h1>
            <div className="wine-box-content__text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Lorem ipsum dolor sit amet consectetur adiscipl gravitae.
              </p>
            </div>
          </div>

          <div className="wine-box-card-container">
            <Link to={{ pathname: "/" }}>
              <img src={card1} alt="[TITLE]" />
            </Link>

            <Link to={{ pathname: "/" }}>
              <img src={card2} alt="[TITLE]" />
            </Link>

            <Link to={{ pathname: "/" }}>
              <img src={card3} alt="[TITLE]" />
            </Link>
          </div>
        </div>

        <div className="wine-box-slider-container">
          <div className="wine-box-slider-container__illustration-wrapper">
            <img
              src={sliderIllustration}
              alt="Illustration of a woman reading a book"
            />
          </div>
          <div className="wine-box-slider" data-uk-slider="loop: true">
            <ul className="uk-slider-items uk-grid">
              {Array(2)
                .fill(null)
                .map((_, index) => (
                  <React.Fragment key={index}>
                    <li className="wine-box-slider__image-wrapper">
                      <Link
                        to="/wine-box"
                        href="<?php echo U?>wine-box/view.php"
                      >
                        <img
                          className="wine-box-slider__image"
                          src={sliderImg}
                          alt="[TITLE]"
                        />
                      </Link>
                    </li>
                    <li className="wine-box-slider__image-wrapper">
                      <Link to="/wine-box">
                        <img
                          className="wine-box-slider__image"
                          src="https://via.placeholder.com/1020x500"
                          alt="[TITLE]"
                        />
                      </Link>
                    </li>
                  </React.Fragment>
                ))}
            </ul>

            <div className="wine-box-slider__arrows-container">
              <div
                className="wine-box-slider__arrow wine-box-slider__arrow--previous"
                data-uk-slider-item="previous"
              >
                <img src={previousArrow} alt="Previous" />
              </div>
              <div
                className="wine-box-slider__arrow wine-box-slider__arrow--next"
                data-uk-slider-item="next"
              >
                <img src={nextArrow} alt="Next" />
              </div>
            </div>
          </div>
        </div>
      </ContentSection>

      <Newsletter purple />

      <SelectionOfTheWeek>
        <MuiContainer maxWidth="xl">
          <div className="wine-box-selection__container">
            <h1 className="wine-box-selection__title">Selection of the week</h1>

            <div className="wine-box-selection__items">
              <Link to="/wine-box">
                <img src={selection1} alt="[TITLE]" />
              </Link>
              <Link to="/wine-box">
                <img src={selectionPurple} alt="[TITLE]" />
              </Link>
              <Link to="/wine-box">
                <img src={selectionYellow} alt="[TITLE]" />
              </Link>
              <Link to="/wine-box">
                <img src={selectionPurple} alt="[TITLE]" />
              </Link>
              <Link to="/wine-box">
                <img src={selectionRed} alt="[TITLE]" />
              </Link>
              <Link to="/wine-box">
                <img src={selectionYellow} alt="[TITLE]" />
              </Link>
            </div>
          </div>
        </MuiContainer>
      </SelectionOfTheWeek>

      <NextEventsSection purple />

      <SliderSection
        title="Wineries"
        subtitle="Meet our winemakers"
        route="/wineries"
      />

      <NaturalWineSection />
    </main>
  );
}
