import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 375px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 3.5rem;
  border-radius: 1rem;
  z-index: 1000;
`;
