import { Fragment } from "react";
import { Link } from "react-router-dom";

export function HeaderLinks() {
  return (
    <>
      <Link className="navbar__link" to="/wine-list">
        Wine List
      </Link>
      <Link className="navbar__link" to="/">
        Wine Regions
      </Link>
      <Link className="navbar__link" to="/wineries">
        Wineries
      </Link>
      <Link className="navbar__link" to="/">
        Organic Wine
      </Link>
      <Link className="navbar__link" to="/">
        Blog
      </Link>
      <Link className="navbar__link" to="/contact">
        Contact
      </Link>
    </>
  );
}
