import { Helmet } from "react-helmet-async";
import { Container as MuiContainer } from "@material-ui/core";

import { LoginForm } from "../../components/LoginForm";
import { AuthenticatedUserRedirect } from "../../components/Redirect/AuthenticatedUserRedirect";

import { Container } from "./styles";

import cloud1 from "../../assets/images/login/cloud-1.png";
import cloud2 from "../../assets/images/login/cloud-2.png";
import leaves from "../../assets/images/login/leaves.png";
import peopleIllustration from "../../assets/images/login/login-illustration-people.png";

export function Login() {
  return (
    <main>
      <Helmet>
        <title>La Quincave | Login</title>
      </Helmet>

      <AuthenticatedUserRedirect to="/" />

      <Container>
        <MuiContainer maxWidth="xl">
          <div className="login__container">
            <div className="login__content-wrapper">
              <div className="login__content-container">
                <LoginForm />
              </div>
            </div>
            <div className="login__illustration-wrapper">
              <img
                className="login__illustration-people"
                src={peopleIllustration}
                alt="people illustration"
              />
              <img
                className="login__illustration-cloud-1"
                src={cloud1}
                alt="cloud"
              />
              <img
                className="login__illustration-cloud-2"
                src={cloud2}
                alt="cloud"
              />
              <img
                className="login__illustration-leaves"
                src={leaves}
                alt="leaves"
              />
            </div>
          </div>
        </MuiContainer>
      </Container>
    </main>
  );
}
