import { Rating as MuiRating } from "@material-ui/lab";
import styled from "styled-components";

import headerBg from "../../assets/images/wine-box-inner/header-bg.png";

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Header = styled.header`
  background: #fbf7f2 url(${headerBg}) no-repeat 1635px bottom;
  min-height: 570px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;

  ${props => props.theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
  }

  .header__slider {
    position: relative;

    .uk-slider-items {
      li {
        width: 100%;
      }
    }

    img {
      width: 100%;
      height: 570px;
      object-fit: cover;
    }

    .uk-dotnav {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);

      li {
        a {
          background: rgba(255, 255, 255, 0.5);
          color: #fff;
        }

        &.uk-active a {
          background: #fff;
        }
      }
    }
  }

  /*****************************************************************************
  * Header Container
  *****************************************************************************/

  .header__container-wrapper {
    width: 100%;
    height: 100%;
    max-width: calc(550px - 3rem);
    padding: 3rem 0;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    ${props => props.theme.breakpoints.down("sm")} {
      max-width: unset;
      align-items: center;
      text-align: center;
      padding: 3rem 3rem;
    }
  }

  .header__container {
    width: 100%;
    max-width: 400px;
  }

  .header__title {
    color: #383838;
    font-size: 2.3rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    display: block;
    margin-bottom: 1rem;
  }

  .header__average-rating-container {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    ${props => props.theme.breakpoints.down("sm")} {
      justify-content: center;
    }
  }

  .header__average-rating-text {
    font-size: 1.4rem;
  }

  .header__discount-text {
    display: inline-block;
    margin-right: 1rem;

    color: #cf4f50;
    font-size: 2rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    text-decoration: line-through;
  }

  .header__discount-badge {
    display: inline-block;
    background: #f9d889;
    color: #383838;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
  }

  .header__price {
    display: block;
    color: #3eb969;
    font-size: 4.4rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    margin-top: 2rem;
  }

  .header__description {
    color: #383838;
    font-family: "Poppins", sans-serif;
    font-size: 1.4rem;
    margin: 2rem 0;
  }

  /*****************************************************************************
  * Header Actions Container
  *****************************************************************************/

  .header__actions-container {
    display: flex;
    align-items: center;

    background: #3eb969;
    max-width: fit-content;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    color: #fff;

    ${props => props.theme.breakpoints.down("sm")} {
      margin: 0 auto;
    }

    .cart-button {
      cursor: pointer;
      background: none;
      border: none;

      color: #fff;
      font-size: 1.8rem;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
    }

    .amount-actions {
      display: flex;
      align-items: center;

      .amount {
        font-size: 2rem;
        margin: 0 1.5rem;
      }

      button {
        color: #fff;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 1.4rem;
        padding: 0.5rem;
      }
    }
  }

  .header__actions-divider {
    width: 1px;
    min-height: 4rem;
    background: #ddf3e5;
    margin: 0 1.5rem;
  }
`;

export const Rating = styled(MuiRating)`
  color: var(--app-red);
  font-size: 2.4rem;
`;

export const ContentSection = styled.section`
  padding: 7rem 0;
  background: #fff;

  .wine-box-inner-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  .wine-box-inner__title {
    color: #c1b2b7;
    font-size: 2.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
  }

  /*****************************************************************************
  * Wine Box Inner Slider
  *****************************************************************************/

  .wine-box-inner-slider {
    .uk-slider-items {
      margin-left: -15px;
    }
  }

  /*****************************************************************************
  * Wine Box Inner Product Card
  *****************************************************************************/

  .wine-box-inner-product-card {
    width: 250px;
    height: 100%;
    border: 2px solid #e5d6db;
    padding: 2.5rem 1.5rem;

    .product-divider {
      margin: 2rem 0;
    }

    &.active {
      border: 4px solid var(--app-red);
    }
  }

  .wine-box-inner-product-card__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .uk-badge {
      position: absolute;
      left: 0;

      min-width: 4rem;
      height: 4rem;

      color: #fff;
      font-family: "Libre Baskerville", "Poppins", sans-serif;
      font-weight: bold;
      font-size: 1.4rem;
      background: var(--app-red);
      border-radius: 50%;
    }
  }

  .wine-box-inner-product-card__text {
    color: #707070;
    font-size: 1.8rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    text-align: center;
    display: block;
  }

  /*****************************************************************************
  * Wine Box Inner Feature
  *****************************************************************************/

  .wine-box-inner-feature-grid {
    margin-top: 4rem;

    display: grid;
    grid-template-columns: 1fr 1fr;

    ${props => props.theme.breakpoints.down("sm")} {
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 2rem;

      .wine-box-inner__title {
        text-align: center;
        margin-bottom: 3rem;
      }
    }
  }

  .wine-box-inner-features-grid {
    display: grid;
    column-gap: 2rem;
    grid-template-columns: 1fr 1fr;

    ${props => props.theme.breakpoints.down("sm")} {
      display: flex;
      row-gap: 2rem;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .wine-box-inner-feature {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    margin-bottom: 3rem;
  }

  .wine-box-inner-feature__title {
    display: block;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 1.3rem;
  }

  .wine-box-inner-feature__description {
    display: block;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 1.4rem;
  }
`;

export const RatingSection = styled.section`
  padding: 7rem 0;
  background: #fcf6f6;

  .rating-section__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 3rem;

    margin-bottom: 6rem;

    ${props => props.theme.breakpoints.down("sm")} {
      flex-direction: column;
      text-align: center;
    }
  }

  .rating-section__header-message {
    color: #858484;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
  }

  .rating-section__title {
    color: #858484;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    font-size: 2.3rem;

    .amount {
      font-family: "Libre Baskerville", "Poppins", sans-serif;
      font-weight: normal;
      font-size: 1.8rem;
    }
  }

  .rating-section__rate-container {
    display: flex;
    align-items: flex-end;
    column-gap: 1.5rem;
  }

  .rating-section__star {
    polygon {
      color: #b0adaa;
      fill: #b0adaa;
      transition: all 0.2s;
    }

    &.active,
    &:hover {
      polygon {
        color: var(--app-red);
        fill: var(--app-red);
      }
    }
  }

  .rating-section__rate-text {
    font-size: 1.4rem;
  }

  /*****************************************************************************
  * Wine Box Inner Rating Comments
  *****************************************************************************/

  .rating-section__comments-container {
    width: 100%;
    max-width: 850px;
    margin-left: auto;
    margin-top: 1.5rem;
  }

  .rating-section-comment {
    margin-bottom: 3rem;

    & + .rating-section-comment {
      border-top: 1px solid #b0adaa;
      padding-top: 3rem;
    }

    display: flex;
    column-gap: 1.5rem;
  }

  .rating-section-comment__avatar {
    width: 54px;
    height: 54px;
    object-fit: cover;
    border-radius: 50%;
  }

  .rating-section-comment__username {
    display: block;
    color: #111;
    font-size: 1.3rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }

  .rating-section-comment__text {
    font-size: 1.4rem;
    color: #111;
    font-family: "Poppins", sans-serif;
    margin: 2rem 0;
  }

  .rating-section-comment__footer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-end;

    .rating-section__star {
      pointer-events: none;
    }
  }

  .rating-section-comment__date {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  .rating-section-comment__form {
    display: flex;
    flex-direction: column;

    textarea {
      border: 2px solid #c9c9cb;
      border-radius: 1rem;
      resize: none;
      margin-top: 1rem;
      padding: 1.5rem 1rem;

      color: #111;
      font-family: "Poppins", sans-serif;
      font-size: 1.4rem;

      &::placeholder {
        color: #111;
        font-family: "Poppins", sans-serif;
        font-size: 1.4rem;
      }
    }

    .rating-section-comment__submit {
      margin-top: 2rem;
      margin-left: auto;

      color: #fff;
      font-size: 1.5rem;
      font-family: "Poppins", sans-serif;
      font-weight: bold;

      background: var(--app-red);
      border: none;
      padding: 1.5rem;
      border-radius: 1rem;

      cursor: pointer;
    }
  }
`;
