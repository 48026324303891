import { Fragment, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { VscChevronRight } from "react-icons/vsc";

import sidebarImg from "../../assets/temp/wine-list-sidebar-img.png";
import { Sidebar, StyledRangeSlider, SidebarAd } from "./styles";

export function WineListSidebarContent() {
  const [rangeValue, setRangeValue] = useState([500, 1000]);

  function handleRangeSliderChange(_event: any, newValue: number | number[]) {
    setRangeValue(newValue as number[]);
  }

  return (
    <>
      <Sidebar>
        <div className="wine-list-sidebar__container">
          <ul className="wine-list-sidebar-accordion" data-uk-accordion>
            <li className="uk-open">
              <button
                type="button"
                className="uk-accordion-title uk-accordion-title--first"
              >
                Show All Categories
              </button>
              <div className="wine-list-sidebar-accordion__container uk-accordion-content">
                <div className="wine-list-sidebar-accordion__group">
                  <span className="wine-list-sidebar-accordion__categories-title">
                    Options Wines <span className="amount">(2079)</span>
                  </span>
                  <ul data-uk-accordion="multiple: true">
                    <li className="wine-list-sidebar-accordion__category-item">
                      <button type="button" className="uk-accordion-title">
                        <VscChevronRight />
                        Red Wines <span className="amount">(847)</span>
                      </button>
                      <div className="uk-accordion-content">
                        <div className="wine-list-sidebar-accordion__category-container">
                          <button type="button">
                            Lorem Ipsum <span className="amount">(341)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(248)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(170)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(89)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(9)</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="wine-list-sidebar-accordion__category-item">
                      <button type="button" className="uk-accordion-title">
                        <VscChevronRight />
                        White Wines <span className="amount">(3941)</span>
                      </button>
                      <div className="uk-accordion-content">
                        <div className="wine-list-sidebar-accordion__category-container">
                          <button type="button">
                            Lorem Ipsum <span className="amount">(341)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(248)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(170)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(89)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(9)</span>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="wine-list-sidebar-accordion__group">
                  <span className="wine-list-sidebar-accordion__categories-title">
                    Group 2 <span className="amount">(2079)</span>
                  </span>
                  <ul data-uk-accordion="multiple: true">
                    <li className="wine-list-sidebar-accordion__category-item">
                      <button type="button" className="uk-accordion-title">
                        <VscChevronRight />
                        Lorem Ipsum <span className="amount">(847)</span>
                      </button>
                      <div className="uk-accordion-content">
                        <div className="wine-list-sidebar-accordion__category-container">
                          <button type="button">
                            Lorem Ipsum <span className="amount">(341)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(248)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(170)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(89)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(9)</span>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="wine-list-sidebar-accordion__category-item">
                      <button type="button" className="uk-accordion-title">
                        <VscChevronRight />
                        Lorem Ipsum <span className="amount">(3941)</span>
                      </button>
                      <div className="uk-accordion-content">
                        <div className="wine-list-sidebar-accordion__category-container">
                          <button type="button">
                            Lorem Ipsum <span className="amount">(341)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(248)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(170)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(89)</span>
                          </button>
                          <button type="button">
                            Lorem Ipsum <span className="amount">(9)</span>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>

          <div>
            <span className="wine-list-sidebar__title">Filters</span>
            <div className="wine-list-sidebar__filter-group">
              <span className="wine-list-sidebar__filter-group-title">
                Price
              </span>
              <StyledRangeSlider
                value={rangeValue}
                onChange={handleRangeSliderChange}
                min={0}
                max={2000}
                step={50}
              />
              <span className="wine-list-sidebar__price-slider-text">
                Price: €{" "}
                <span className="slider-min-value">{rangeValue[0]}</span> - €{" "}
                <span className="slider-max-value">{rangeValue[1]}</span>
              </span>
            </div>

            <div className="wine-list-sidebar__filter-group">
              <span className="wine-list-sidebar__filter-group-title">
                Wineries
              </span>
              <div className="wine-list-sidebar__checkbox-container">
                <label>
                  <input className="uk-checkbox" type="checkbox" />
                  Lorem Ipsum <span className="amount">(167)</span>
                </label>
                <label>
                  <input className="uk-checkbox" type="checkbox" />
                  Lorem Ipsum <span className="amount">(104)</span>
                </label>
                <label>
                  <input className="uk-checkbox" type="checkbox" />
                  Lorem Ipsum <span className="amount">(72)</span>
                </label>
                <label>
                  <input className="uk-checkbox" type="checkbox" />
                  Lorem Ipsum <span className="amount">(31)</span>
                </label>
                <label>
                  <input className="uk-checkbox" type="checkbox" />
                  Lorem Ipsum <span className="amount">(28)</span>
                </label>
              </div>
              <button className="show-more-button" type="button">
                + Show more
              </button>
            </div>

            <div className="wine-list-sidebar__filter-group">
              <span className="wine-list-sidebar__filter-group-title">
                Types of grapes
              </span>
              <div className="wine-list-sidebar__checkbox-container">
                <label>
                  <input className="uk-checkbox" type="checkbox" />
                  Lorem Ipsum <span className="amount">(149)</span>
                </label>
                <label>
                  <input className="uk-checkbox" type="checkbox" />
                  Lorem Ipsum <span className="amount">(135)</span>
                </label>
                <label>
                  <input className="uk-checkbox" type="checkbox" />
                  Lorem Ipsum <span className="amount">(48)</span>
                </label>
                <label>
                  <input className="uk-checkbox" type="checkbox" />
                  Lorem Ipsum <span className="amount">(11)</span>
                </label>
                <label>
                  <input className="uk-checkbox" type="checkbox" />
                  Lorem Ipsum <span className="amount">(8)</span>
                </label>
              </div>
              <button className="show-more-button" type="button">
                + Show more
              </button>
            </div>

            <div className="uk-margin-top">
              <button
                className="wine-list-sidebar__apply-button uk-button"
                type="button"
              >
                Apply
              </button>
              <button
                className="wine-list-sidebar__reset-button uk-button"
                type="button"
              >
                Reset
              </button>
            </div>
          </div>

          <div className="uk-margin-large-top" data-uk-slider>
            <div className="wine-list-sidebar__title latest-product-header">
              <span>Latest Products</span>
              <div className="latest-product-header__icon-container">
                <div data-uk-slider-item="previous">
                  <FaChevronLeft />
                </div>
                <div data-uk-slider-item="next">
                  <FaChevronRight />
                </div>
              </div>
            </div>

            <div className="latest-product-slider">
              <ul className="uk-slider-items uk-grid">
                <li className="latest-product-slider__item">
                  <div className="latest-product-slider__product-wrapper">
                    <img
                      src="https://via.placeholder.com/100x93"
                      alt="[TITLE]"
                    />
                    <div className="latest-product-slider__product-text-wrapper">
                      <strong className="latest-product-slider__text">
                        Lorem ipsum dolor sit amet
                      </strong>
                      <span className="latest-product-slider__price">
                        $49,00
                      </span>
                    </div>
                  </div>
                  <div className="latest-product-slider__product-wrapper">
                    <img
                      src="https://via.placeholder.com/100x93"
                      alt="[TITLE]"
                    />
                    <div className="latest-product-slider__product-text-wrapper">
                      <strong className="latest-product-slider__text">
                        Lorem ipsum dolor sit amet
                      </strong>
                      <span className="latest-product-slider__price">
                        $49,00
                      </span>
                    </div>
                  </div>
                  <div className="latest-product-slider__product-wrapper">
                    <img
                      src="https://via.placeholder.com/100x93"
                      alt="[TITLE]"
                    />
                    <div className="latest-product-slider__product-text-wrapper">
                      <strong className="latest-product-slider__text">
                        Lorem ipsum dolor sit amet
                      </strong>
                      <span className="latest-product-slider__price">
                        $49,00
                      </span>
                    </div>
                  </div>
                </li>
                <li className="latest-product-slider__item">
                  <div className="latest-product-slider__product-wrapper">
                    <img
                      src="https://via.placeholder.com/100x93"
                      alt="[TITLE]"
                    />
                    <div className="latest-product-slider__product-text-wrapper">
                      <strong className="latest-product-slider__text">
                        Lorem ipsum dolor sit amet
                      </strong>
                      <span className="latest-product-slider__price">
                        $49,00
                      </span>
                    </div>
                  </div>
                  <div className="latest-product-slider__product-wrapper">
                    <img
                      src="https://via.placeholder.com/100x93"
                      alt="[TITLE]"
                    />
                    <div className="latest-product-slider__product-text-wrapper">
                      <strong className="latest-product-slider__text">
                        Lorem ipsum dolor sit amet
                      </strong>
                      <span className="latest-product-slider__price">
                        $49,00
                      </span>
                    </div>
                  </div>
                  <div className="latest-product-slider__product-wrapper">
                    <img
                      src="https://via.placeholder.com/100x93"
                      alt="[TITLE]"
                    />
                    <div className="latest-product-slider__product-text-wrapper">
                      <strong className="latest-product-slider__text">
                        Lorem ipsum dolor sit amet
                      </strong>
                      <span className="latest-product-slider__price">
                        $49,00
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Sidebar>

      <SidebarAd>
        <img src={sidebarImg} alt="[TITLE]" />
      </SidebarAd>
    </>
  );
}
