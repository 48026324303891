import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";

import { api } from "../../services/api";

import { Container, Map } from "./styles";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";

interface SettingsData {
  phone: string;
  address: string;
  email: string;
}

export function Contact() {
  const { data: settings, isLoading: isLoadingSettings } = useQuery("settings", async () => {
    const response = await api.get<SettingsData>("/settings/all.php");

    console.log(response.data)

    return response.data;
  });

  return (
    <main>
      <Helmet>
        <title>La Quincave | Contact</title>
      </Helmet>

      <Container>
        <Map
          title="contact-map"
          loading="lazy"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.7989390686503!2d2.3279438158531027!3d48.84297370974593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e671cf1152050b%3A0x7ff9d1d8f8eac8d!2s17%20Rue%20Br%C3%A9a%2C%2075006%20Paris%2C%20Fran%C3%A7a!5e0!3m2!1spt-BR!2sbr!4v1621447746026!5m2!1spt-BR!2sbr"
        />

        <div className="contact__container">
          <div className="contact__wrapper">
            <h1 className="contact__title">Lorem ipsum dolor sit?</h1>
            <p className="contact__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <form className="contact-form">
              <input
                className="contact-form__input"
                type="text"
                name="Name"
                placeholder="Complete name"
                required
              />
              <div className="contact-form__input-group">
                <input
                  className="contact-form__input"
                  type="text"
                  name="Phone"
                  placeholder="(00) 0000-0000"
                  required
                />
                <input
                  className="contact-form__input"
                  type="email"
                  name="E-mail"
                  placeholder="johndoe@gmail.com"
                  required
                />
              </div>
              <textarea
                className="contact-form__input"
                name="Message"
                cols={30}
                rows={8}
                placeholder="Message"
              />
              <button className="contact-form__button" type="submit">
                Send a message
              </button>
            </form>
          </div>

          <div className="contact__info-container">
            <div className="contact__info-wrapper">
              <span className="contact__info-title">Phone</span>
              {isLoadingSettings && <Skeleton variant="text" />}
              {!isLoadingSettings && <span className="contact__info-text">{settings.phone}</span>}
            </div>

            <div className="contact__info-wrapper">
              <span className="contact__info-title">Address</span>
              {isLoadingSettings && <Skeleton variant="text" />}

              {!isLoadingSettings && (
                <Link
                  className="contact__info-text"
                  to={{ pathname: `https://www.google.com/maps/search/?api=1&query=${settings.address}` }}
                  target="_blank"
                >
                  {settings.address}
                </Link>
              )}
            </div>

            <div className="contact__info-wrapper">
              <span className="contact__info-title">E-mail</span>
              {isLoadingSettings && <Skeleton variant="text" />}

              {!isLoadingSettings && (
                <a className="contact__info-text" href={`mailto:${settings.email}`}>
                  {settings.email}
                </a>
              )}
            </div>
          </div>
        </div>
      </Container>
    </main>
  );
}
