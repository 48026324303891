import styled from "styled-components";

import winedropRed from "../../assets/icons/winedrop-red.png";

export const Divider = styled.hr`
  margin-bottom: 1rem;
  margin: 0;
  background-size: contain;
  background-image: url(${winedropRed});

  &::before,
  &::after {
    border-bottom: 2px solid #e7d9dd;
  }
`;
