import styled from "styled-components";

import bgLeaf1 from "../../assets/images/natural-wine-section/bg-leaf-1.png";
import bgLeaf2 from "../../assets/images/natural-wine-section/bg-leaf-2.png";
import bgShape from "../../assets/images/natural-wine-section/bg-shape.png";

export const Container = styled.section`
  background: url(${bgLeaf1}) no-repeat -100px -20px,
    url(${bgLeaf2}) no-repeat calc(100% + 50px) calc(100% + 70px) #d63c3f;

  padding: 8rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  &::before {
    position: absolute;
    top: -1px;
    content: "";
    width: 100%;
    height: 30px;
    background: url(${bgShape}) repeat center top;
  }

  &::after {
    position: absolute;
    bottom: -1px;
    content: "";
    width: 100%;
    height: 30px;
    background: url(${bgShape}) repeat center bottom;
    transform: rotate(180deg);
  }

  ${props => props.theme.breakpoints.down("xs")} {
    background-size: 250px;
  }

  .natural-wine-section__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6rem;

    ${props => props.theme.breakpoints.down("md")} {
      flex-direction: column;
    }
  }

  .natural-wine-section__content-wrapper {
    width: 100%;
    max-width: 500px;
  }

  .natural-wine-section__title {
    color: var(--app-yellow);
    font-size: 4.3rem;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
  }

  .natural-wine-section__text {
    color: #fff;
    margin: 6rem 0;
  }

  .natural-wine-section__button {
    color: #fff !important;
    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;

    border: 1px solid #fff;
    border-radius: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 180px;
    padding: 1.3rem;

    transition: transform 0.3s;

    &:hover {
      transform: translateY(-3px);
    }
  }
`;
