import { Link } from "react-router-dom";

import styled from "styled-components";

import shapeImg from "../../assets/images/footer/footer-shape.png";

export const StyledFooter = styled.footer`
  color: #fff;
  background: #212121;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 54px;
    background: url(${shapeImg}) top center;
    margin-top: -53px;
    position: relative;
  }

  ${props => props.theme.breakpoints.down("sm")} {
    * {
      text-align: center;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1630px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
`;

/** *****************************************************************************
 * Top Container
 ****************************************************************************** */

export const TopContainer = styled.div`
  padding-bottom: 5rem;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 3rem;

  ${props => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Address = styled.div`
  font-size: 1.4rem;
  letter-spacing: 0.16rem;

  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  ${props => props.theme.breakpoints.down("md")} {
    width: 100%;
    align-items: center;
  }
`;

export const Logo = styled.img`
  max-width: 25rem;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${props => props.theme.breakpoints.down("sm")} {
    align-items: center;
  }
`;

export const LinksTitle = styled.h2`
  color: #fff;
  font-size: 2.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0.16rem;
  margin: 0;

  &::after {
    content: "";
    display: block;
    width: 70px;
    height: 2px;
    background: #b7b7b7;
    margin: 2rem 0 2.5rem 0;
  }

  ${props => props.theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterLink = styled(Link)`
  color: #929292;
  font-size: 1.4rem;
  letter-spacing: 0.16rem;

  max-width: 190px;

  transition: all 0.2s;

  &:hover {
    color: #fff;
    transform: translateY(-3px);
  }

  & + a {
    margin-top: 0.5rem;
  }
`;

export const LinkGrid = styled.div`
  display: flex;
  column-gap: 3rem;

  ${props => props.theme.breakpoints.down("sm")} {
    display: block;
  }
`;

/** *****************************************************************************
 * Bottom Container
 ****************************************************************************** */

export const BottomContainer = styled.div`
  border-top: 1px solid #fff;
  padding: 3rem 4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  ${props => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Copyright = styled.span`
  font-size: 1.5rem;

  ${props => props.theme.breakpoints.down("sm")} {
    order: 2;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 3.5rem;

  ${props => props.theme.breakpoints.down("sm")} {
    order: 1;
  }

  ${props => props.theme.breakpoints.down(350)} {
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
`;

export const SocialMediaLink = styled(Link)`
  padding: 0 0.5rem;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-3px);
  }

  svg {
    font-size: 2.2rem;
  }
`;
