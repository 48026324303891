import styled from "styled-components";

export const Container = styled.section`
  .blog-section__container {
    width: 100%;
    max-width: 1340px;
    margin: 0 auto;

    ${props => props.theme.breakpoints.down("md")} {
      max-width: 640px;
    }
  }

  .blog-section__slider-wrapper {
    position: relative;
  }

  .blog-section__slidenav {
    display: inline-block;

    ${props => props.theme.breakpoints.down(720)} {
      display: none;
    }
  }

  .blog-section__dotnav {
    display: none;

    a:focus {
      background-color: transparent;
      border-color: rgba(102, 102, 102, 0.4);
    }

    .uk-active a {
      background: #707070;
    }

    ${props => props.theme.breakpoints.down(720)} {
      display: flex;
    }
  }

  /*******************************************************************************
  * Blog Section Post
  *******************************************************************************/

  .blog-section-post {
    display: flex;
    align-items: center;

    ${props => props.theme.breakpoints.down("xs")} {
      flex-direction: column;
    }
  }

  .blog-section-post__image-wrapper {
    position: relative;
    width: 100%;
    max-width: 330px;
    height: 390px;
    z-index: 0;

    ${props => props.theme.breakpoints.down("xs")} {
      height: 250px;
    }
  }

  .blog-section-post__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-section-post__date-wrapper {
    background: #fff;
    padding: 1rem 1.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }

  .blog-section-post__day {
    color: #262626;
    font-size: 2.3rem;
  }

  .blog-section-post__month {
    color: #262626;
    font-size: 1.4rem;
  }

  .blog-section-post__content-container {
    width: 100%;
    min-height: 290px;

    margin-left: -20px;
    padding: 0 3rem;

    background: #fff;
    box-shadow: 0 2px 16px 0 rgba($color: #000000, $alpha: 0.07);

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 1;

    ${props => props.theme.breakpoints.down("xs")} {
      max-width: 330px;
      margin-left: 0;
    }
  }

  .blog-section-post__content-wrapper {
    width: 100%;
    max-width: 240px;
    padding-bottom: 1.5rem;

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .blog-section-post__category {
    color: #fff;
    font-size: 1.4rem;
    font-family: "Poppins";
    text-align: center;

    background: var(--app-red);

    max-width: max-content;
    padding: 1rem 1.5rem;
    margin-top: -1.5rem;
    margin-bottom: 2rem;
  }

  .blog-section-post__title {
    color: #707070;
    font-family: "Libre Baskerville", "Poppins", sans-serif;
    font-weight: bold;
    font-size: 2.3rem;
    margin: 0;
  }

  .blog-section-post__text {
    color: #707070;
    font-size: 1.4rem;
    margin: 3rem 0;
  }

  .blog-section-post__link {
    color: var(--app-red) !important;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
  }
`;
